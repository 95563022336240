import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";

import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

interface Props {
  showDelete: boolean;
  isWaiting?: boolean;
  disabled?: boolean;
  onClose: () => void;
  onDelete?: () => void;
}

const FormActions: React.FC<Props> = ({
  showDelete,
  isWaiting,
  disabled,
  onClose,
  onDelete,
}) => {
  return (
    <DialogActions>
      <Stack direction="row" spacing={2} sx={{ mt: 1, width: "100%" }}>
        <Button
          variant="outlined"
          color="inherit"
          fullWidth
          onClick={onClose}
          startIcon={<CloseIcon />}
        >
          Cancel
        </Button>
        <LoadingButton
          color="secondary"
          disabled={disabled}
          fullWidth
          loading={isWaiting}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          type="submit"
          variant="contained"
        >
          <span>Save</span>
        </LoadingButton>
      </Stack>
      {showDelete && (
        <Button
          variant="outlined"
          color="error"
          fullWidth
          onClick={onDelete}
          startIcon={<DeleteIcon />}
          sx={{ mt: 1 }}
        >
          Delete
        </Button>
      )}
    </DialogActions>
  );
};

export default FormActions;
