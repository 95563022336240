import moment, { Moment } from "moment";

export type Dates = Moment | string;

export const getMomentDate = (date: Dates) => moment(date);

export const getNextMonth = (date: Dates, add = 1) =>
  moment(date).add(add, "months").startOf("month");
export const getPrevMonth = (date: Dates) =>
  moment(date).subtract(1, "months").startOf("month");
export const getStartOfMonth = (date: Dates) =>
  getDbDate(moment(date).startOf("month"));
export const getEndOfMonth = (date: Dates) =>
  getDbDate(moment(date).endOf("month"));

export const getMonthName = (date: Dates) => moment(date).format("MMM");
export const getYear = (date: Dates) => moment(date).format("YYYY");
export const getMonth = (date: Dates) => moment(date).format("MM");
export const getDate = (date: Dates) => moment(date).format("DD");
export const getWeekDay = (date: Dates) => moment(date).format("dddd");

export const getChartDate = (date: Dates) => moment(date).format("DD MMM");
export const getTooltipDate = (date: Dates) =>
  moment(date).format("MMM DD, YYYY");

export const getDbDateKey = (date: Dates) => moment(date).format("MM-YYYY");
export const getDbDate = (date: Dates) => moment(date).format("YYYY-MM-DD");

export const getRemainingMonths = (endDate: Dates, startDate: Dates) => {
  return moment(endDate).diff(moment(startDate), "months");
};

export const getLoanDate = (loanDate: Dates, curDate: Dates) =>
  moment(
    new Date(`${getYear(curDate)}-${getMonth(curDate)}-${getDate(loanDate)}`)
  );

export const getLoanEndDate = (date: Dates, add: number) =>
  getDbDate(getLoanDate(date, getNextMonth(date, add - 1)));

export const getAllDatesInMonth = (date: Dates) => {
  const momentDate = moment(date);

  var daysInMonth = momentDate.daysInMonth();
  var arrDays = [];

  while (daysInMonth) {
    var current = momentDate.date(daysInMonth);
    arrDays.push(getChartDate(current));
    daysInMonth--;
  }

  return arrDays.reverse();
};

export const defaultDate = moment(moment(new Date()).format("YYYY-MM-01"));

export const monthStartFormat = (d: Dates) => moment(d).format("YYYY-MM-01");

export const checkDateIsGreater = (d: Dates) => {
  const today = monthStartFormat(moment());
  const compareDate = monthStartFormat(d);

  if (compareDate > today) {
    return "great";
  } else if (compareDate < today) {
    return "less";
  } else {
    return "same";
  }
};

export const today = getDbDate(moment());
export const remainingDays = moment().daysInMonth() - +moment().format("D");
export const getDueDays = (date: Dates) =>
  moment(date).diff(moment(today), "days");

export const getDateWithTime = (d: Moment | null | undefined) => {
  const time = moment().format("HH:mm:ss");
  return moment(d).format(`YYYY-MM-DD ${time}`);
};

export const isFuture = (date: Dates) => getDbDate(date) > today;

export const creditDateKey = (d: Dates) =>
  moment(getPrevMonth(d)).format("YYYY-MM-16");

export const getCreditCycle = (d: Dates) => {
  const start = moment(getPrevMonth(d)).format("YYYY-MM-17");
  return `Billing Cycle: ${getChartDate(start)} - ${getChartDate(d)}`;
};

export const getStartEndTime = (date: Dates) => {
  const startOfMonth = moment(date)
    .startOf("month")
    .format("YYYY-MM-DD 00:00:00");
  const endOfMonth = moment(date).endOf("month").format("YYYY-MM-DD 11:59:59");
  return {
    start: startOfMonth,
    end: endOfMonth,
  };
};

export const getModalDate = (date: Dates) => {
  const curMonth = moment(date).format("YYYY-MM");
  const curDate = moment().format("DD");
  return getDateWithTime(moment(`${curMonth}-${curDate}`));
};
