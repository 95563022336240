import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import AmountIcon from "../UI/AmountIcon";
import DateCard from "../UI/DateCard";

import { content_xs, formatCurrency } from "../../helpers/utils";
import { AllTxns } from "../../models";

interface Props {
  item: AllTxns;
  tab: number;
  onSelectItem: () => void;
}
const TransactionItem: React.FC<Props> = ({ item, tab, onSelectItem }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    }}
  >
    <Button color="inherit" onClick={onSelectItem} sx={{ flexGrow: 1 }}>
      <Box sx={{ p: 1 }}>
        <DateCard date={item.date} />
      </Box>
      <Box sx={{ flexGrow: 1, textAlign: "left", wordBreak: "break-all" }}>
        <Typography variant="body1" component="div" role="name">
          {item.name}
        </Typography>
        <Typography
          variant="caption"
          component="div"
          role="balance"
          sx={{ color: "#d8c9c9" }}
        >
          {item.status}
        </Typography>
      </Box>
      <Box sx={{ p: 1 }}>
        <Typography variant="h6" component="div" role="amount" sx={content_xs}>
          <AmountIcon amount={item.amount} />
        </Typography>
        <Typography
          variant="body2"
          component="div"
          role="balance"
          sx={{
            ...content_xs,
            color: "#d8c9c9",
            textAlign: "right",
          }}
        >
          {tab === 0 && `Balance: ${formatCurrency(item.balance)}`}
          {tab === 3 && item?.remaining}
        </Typography>
      </Box>
    </Button>
  </Box>
);

export default TransactionItem;
