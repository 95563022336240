import { Outlet } from "react-router-dom";

import classes from "./AuthLayout.module.css";

const AuthLayout = () => {
  return (
    <div className={classes.center}>
      <div className={classes.card}>
        <div className={classes.front}>
          <div className={classes["strip-bottom"]}></div>
          <div className={classes["strip-top"]}></div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
