import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import AmountIcon from "../UI/AmountIcon";

import { content_xs } from "../../helpers/utils";
import { CreditLoan } from "../../models";

interface Props {
  item: CreditLoan;
  onSelectItem: () => void;
}
const CreditLoanItem: React.FC<Props> = ({ item, onSelectItem }) => (
  <ListItem sx={{ p: 0 }}>
    <ListItemButton onClick={onSelectItem}>
      <ListItemText primary={item.name} />
      <ListItemText
        primary={
          <Typography
            variant="h6"
            component="div"
            role="amount"
            sx={content_xs}
          >
            <AmountIcon amount={item.amount} />
          </Typography>
        }
        secondary={
          <Typography
            variant="body2"
            component="div"
            role="balance"
            sx={{
              ...content_xs,
              color: "#d8c9c9",
            }}
          >
            {item.remaining !== undefined && item.remaining}
          </Typography>
        }
        sx={{ textAlign: "end" }}
      />
    </ListItemButton>
  </ListItem>
);

export default CreditLoanItem;
