import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TabPanel from "../UI/TabPanel";

interface Props {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
  headerTitle: string;
}

const TransactionTabsHeader: React.FC<Props> = ({
  children,
  value,
  index,
  headerTitle,
}) => {
  return (
    <TabPanel value={value} index={index}>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {headerTitle}
        </Typography>
        {children}
      </Toolbar>
    </TabPanel>
  );
};

export default TransactionTabsHeader;
