import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

import { useHome } from "../../store/home-context";
import AmountIcon from "../UI/AmountIcon";
import classes from "./SummaryCard.module.css";

interface Props {
  title: string;
  amount: number;
  isLoading: boolean;
}

const SummaryCard: React.FC<Props> = ({ title, amount, isLoading }) => {
  const { selectedBank, banksList } = useHome();

  const defaultBank = banksList.find((bnk) => bnk.id === selectedBank);

  return (
    <Grid
      item
      xs
      sx={{
        display: "flex",
        borderBottom: `1px solid rgba(255, 255, 255, 0.12)`,
        alignItems: "center",
      }}
    >
      <div className={classes.center}>
        <div className={classes.card}>
          <div className={classes.front}>
            <div className={classes["strip-bottom"]}></div>
            <div className={classes["strip-top"]}></div>
            {defaultBank?.id && (
              <Avatar
                src={require(`../../assets/bank/${defaultBank?.bankLogo}`)}
                alt={defaultBank?.id}
                sx={{ position: "absolute", right: "25px", top: "20px" }}
              />
            )}
            <div className={classes.investor}>{title}</div>
            <div className={classes["card-number"]}>
              {isLoading && <Skeleton width="50%" />}
              {!isLoading && <AmountIcon amount={amount} />}
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default SummaryCard;
