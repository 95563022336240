import { AllTxns, Credit, CreditLoan, Loan, Opening, Txn } from "../models";
import {
  Dates,
  creditDateKey,
  getDbDate,
  getDbDateKey,
  getEndOfMonth,
  getMomentDate,
  getStartOfMonth,
  isFuture,
} from "./date";
import { CreditDb } from "./types";

export const formatCurrency = (amount: number) =>
  amount
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getBankLogo = (bankLogo: string) =>
  require(`../assets/bank/${bankLogo}`);

export const filterTxnsByType = (allTxns: AllTxns[], txnType: string[]) =>
  allTxns.filter((txn) => txnType.includes(txn.type)).reverse();
// allTxns.filter((txn) => txn.type === txnType).reverse();

export const filterByDate = (allTxns: AllTxns[], filterType: string) => {
  if (allTxns.length === 0) {
    return [];
  }
  if (filterType === "cleared") {
    return allTxns.filter((tx) => !isFuture(tx.date)).reverse();
  } else if (filterType === "pending") {
    return allTxns.filter((tx) => isFuture(tx.date)).reverse();
  } else {
    return [...allTxns].reverse();
  }
};

export const dataFormater = (number: number) => {
  if (number > 1000000000) {
    return (number / 1000000000).toString() + "B";
  } else if (number > 1000000) {
    return (number / 1000000).toString() + "M";
  } else if (number > 1000) {
    return (number / 1000).toString() + "K";
  } else {
    return number.toString();
  }
};

export const capitalizeWord = (word: string) =>
  word[0].toUpperCase() + word.substring(1);
// d.toLocaleString();
//return new Intl.NumberFormat("en").format(d);

export const filterLoans = (
  loansData: Loan[] | any[],
  selectedDate: Dates
  // selectedBank: string
) => {
  const dbLnDate = getDbDate(selectedDate);
  return loansData.filter(
    (ln) =>
      getStartOfMonth(ln.start_date) <= getDbDate(dbLnDate) &&
      getEndOfMonth(ln.end_date) >= getDbDate(dbLnDate) //&&
    // ln.bank === selectedBank
  );
};

export const filterCreditLoans = (
  loansData: CreditLoan[] | any[],
  selectedDate: Dates
) => {
  const dbLnDate = getDbDate(selectedDate);
  return loansData.filter(
    (ln) =>
      getStartOfMonth(ln.start_date) <= getDbDate(dbLnDate) &&
      getEndOfMonth(ln.end_date) >= getDbDate(dbLnDate)
  );
};

export const filterTxns = (
  txnData: Txn[] | any[],
  selectedDate: Dates,
  selectedBank: string
) =>
  txnData.filter(
    (tx) =>
      getDbDateKey(tx.date) === getDbDateKey(selectedDate) &&
      tx.bank === selectedBank
  );

export const filterCredit = (creditData: CreditDb[], selectedDate: Dates) =>
  creditData.find((cc) => cc.id === creditDateKey(selectedDate));

export const getDueMsg = (days: number) =>
  `Due ${days === 1 ? "tomorrow" : days === 0 ? "today" : `in ${days} days`}`;

export const creditInit = (selectedDate: Dates, loansTotal: number = 0) => {
  return new Credit(creditDateKey(selectedDate), loansTotal);
};

export const openingInit = (
  selectedDate: Dates,
  selectedBank: string,
  amount: number = 0
) => {
  return new Opening("new", amount, getMomentDate(selectedDate), selectedBank);
};

export const content_xs = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "end",
  alignItems: "center",
  workBreak: "break-all",
};

export const getRemainingMsg = (remaining: number, period: number) =>
  `${remaining === 0 ? "Last Emi" : `Remaining EMI: ${remaining}`} (${period})`;

type Total = CreditLoan | Loan | Txn;
export const getTotal = (data: Total[]) => {
  let total = 0;
  data.forEach((d) => {
    total += d.amount;
  });
  return total;
};

export const validateName = (name: string) => {
  if (name.trim().length === 0) {
    return "Enter a Valid Name!";
  }

  if (name.trim().length > 32) {
    return "Name should be greater than 32 characters!";
  }

  /* eslint-disable no-useless-escape */
  const nameRegex = /^[a-zA-Z0-9\-\ ]+$/;
  if (!nameRegex.test(name.trim())) {
    return "Enter a Valid Name!";
  }
  return true;
};

export const validateAmount = (amount: number) =>
  amount < 0 ? "Amount should be greater than Zero!" : true;

export const validatePeriod = (period: number) =>
  period < 1 ? "Total EMI should be greater than Zero!" : true;

export const validatePassword = (password: string) => {
  if (password.trim().length < 6) {
    return "Password needs minimum 6 characters!";
  }
  return true;
};

export const validateUserName = (name: string) => {
  /* eslint-disable no-useless-escape */
  const nameRegex = /^[a-zA-Z\-\ ]+$/;
  if (!nameRegex.test(name.trim())) {
    return "Enter a Valid Name!";
  }
  return true;
};

export const validateEmail = (email: string) => {
  /* eslint-disable no-useless-escape */
  const regX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!regX.test(email.trim())) {
    return "Enter a Valid Email!";
  }
  return true;
};
