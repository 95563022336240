import Box from "@mui/material/Box";

import TransactionChart from "./TransactionChart";
import TransactionTable from "./TransactionTable";

function TransactionSection() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        mt: { xs: 4, sm: 0 },
      }}
    >
      <TransactionChart />
      <TransactionTable />
    </Box>
  );
}

export default TransactionSection;
