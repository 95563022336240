import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";

import { capitalizeWord } from "../../helpers/utils";
import TransactionTabs from "./TransactionTabs";
import TransactionTabsHeader from "./TransactionTabsHeader";

interface Props {
  value: number;
  filterItem: string;
  filterIsOpen: boolean;
  onChangeTab: (event: React.SyntheticEvent, newValue: number) => void;
  onClickFilterButton: (event: React.MouseEvent<HTMLElement>) => void;
  onOpenForm: () => void;
}

const TransactionHeader: React.FC<Props> = ({
  value,
  filterIsOpen,
  filterItem,
  onChangeTab,
  onClickFilterButton,
  onOpenForm,
}) => {
  return (
    <>
      <TransactionTabs value={value} onChangeTab={onChangeTab} />
      <TransactionTabsHeader
        index={0}
        value={value}
        headerTitle={`${capitalizeWord(filterItem)} Transactions`}
      >
        <Tooltip title="Filter list">
          <IconButton
            aria-controls={filterIsOpen ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={filterIsOpen ? "true" : undefined}
            onClick={onClickFilterButton}
          >
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      </TransactionTabsHeader>
      <TransactionTabsHeader index={1} value={value} headerTitle="All Incomes">
        <Tooltip title="Add Income">
          <IconButton onClick={onOpenForm}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </TransactionTabsHeader>
      <TransactionTabsHeader index={2} value={value} headerTitle="All Expenses">
        <Tooltip title="Add Expense">
          <IconButton onClick={onOpenForm}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </TransactionTabsHeader>
      <TransactionTabsHeader index={3} value={value} headerTitle="All Loans">
        <Tooltip title="Add Loan">
          <IconButton onClick={onOpenForm}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </TransactionTabsHeader>
    </>
  );
};

export default TransactionHeader;
