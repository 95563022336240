import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { formatCurrency } from "../../helpers/utils";

const AmountIcon: React.FC<{ amount: number }> = ({ amount }) => {
  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <CurrencyRupeeIcon fontSize="inherit" />
      <Typography variant="inherit">{formatCurrency(amount)}</Typography>
    </Box>
  );
};

export default AmountIcon;
