import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const SummaryTitle = () => {
  return (
    <Grid item xs sx={{ display: "flex", mt: { xs: 2, sm: 0 } }}>
      <Card
        sx={{
          backgroundImage: "none",
          borderBottom: `1px solid rgba(255, 255, 255, 0.12)`,
          borderRadius: 0,
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Overview
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Manage all your personal finances in just one page.
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SummaryTitle;
