import { Fragment } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";

import { CSSObject, Theme, styled, useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import BreadCrumb from "../UI/BreadCrumb";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const ProfileLayout = () => {
  const location = useLocation();
  const theme = useTheme();

  const open = useMediaQuery(theme.breakpoints.up("md"));

  const pageTitle =
    location?.pathname === "/profile"
      ? "Profile"
      : location?.pathname === "/settings"
      ? "Settings"
      : "";

  return (
    <Box sx={{ display: "flex", flexGrow: 1 }}>
      <CssBaseline />
      <Drawer open={open} variant="permanent">
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            {profileUrls.map(({ name, url, Icon }) => (
              <Fragment key={name}>
                <NavLink
                  to={url}
                  style={({ isActive }) => {
                    return {
                      color: "inherit",
                      background: isActive ? "#333" : "inherit",
                      textDecoration: "none",
                    };
                  }}
                >
                  <ListItem disablePadding sx={{ backgroundColor: "inherit" }}>
                    <ListItemButton>
                      <ListItemIcon
                        sx={{ minWidth: { xs: "auto", md: "56px" } }}
                      >
                        <Icon />
                      </ListItemIcon>
                      <ListItemText
                        primary={name}
                        sx={{ display: { xs: "none", md: "block" } }}
                      />
                    </ListItemButton>
                  </ListItem>
                </NavLink>
                <Divider />
              </Fragment>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: { xs: 1, md: 3 }, pt: { xs: 3 } }}
      >
        <BreadCrumb pageTitle={pageTitle} />
        <Outlet />
      </Box>
    </Box>
  );
};

export default ProfileLayout;

const profileUrls = [
  {
    name: "Profile",
    url: "/profile",
    Icon: AccountCircleIcon,
  },
  {
    name: "Settings",
    url: "/settings",
    Icon: SettingsIcon,
  },
];
