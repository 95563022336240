import moment, { Moment } from "moment";

class Txn {
  id: string;
  name: string;
  amount: number;
  date: Moment;
  type: string;
  key: string;
  bank: string;
  remaining: null;

  constructor(
    id: string,
    name: string,
    amount: number,
    date: string,
    type: string,
    key: string,
    bank: string
  ) {
    this.id = id;
    this.name = name;
    this.amount = amount;
    this.date = moment(date);
    this.type = type;
    this.key = key;
    this.bank = bank;
    this.remaining = null;
  }
}

export default Txn;
