import { useMemo } from "react";

import Box from "@mui/material/Box";

import SummaryCard from "./SummaryCard";
import SummaryChart from "./SummaryChart";
import SummaryTitle from "./SummaryTitle";

import { getTotal } from "../../helpers/utils";
import { useTxns } from "../../store/txns-context";

const SummarySection: React.FC = () => {
  const { txns, loans, opening, isLoading } = useTxns();

  const openingTotal = opening.length === 1 ? opening[0].amount : 0;

  const incomeTotal = useMemo(() => {
    const incomes = txns.filter((tx) => tx.type === "income");
    return incomes.length > 0 ? getTotal(incomes) : 0;
  }, [txns]);

  const expenseTotal = useMemo(() => {
    const expenses = txns.filter((tx) => tx.type === "expense");
    return expenses.length > 0 ? getTotal(expenses) : 0;
  }, [txns]);

  const loansTotal = useMemo(
    () => (loans.length > 0 ? getTotal(loans) : 0),
    [loans]
  );

  const totalIncome = openingTotal + incomeTotal;
  const balance = totalIncome - expenseTotal - loansTotal;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { xs: "100%", md: "25%" },
      }}
    >
      <SummaryTitle />
      <SummaryChart incomesTotal={totalIncome} balance={balance} />
      <SummaryCard title="Incomes" amount={incomeTotal} isLoading={isLoading} />
      <SummaryCard
        title="Expenses"
        amount={expenseTotal}
        isLoading={isLoading}
      />
      <SummaryCard title="Loans" amount={loansTotal} isLoading={isLoading} />
    </Box>
  );
};

export default SummarySection;
