import { Outlet } from "react-router-dom";

import Box from "@mui/material/Box";

import Header from "./Header";

interface Props {
  children?: React.ReactNode;
}

const MainLayout: React.FC<Props> = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Header />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            flexGrow: 1,
          }}
        >
          {/* layout children starts here */}
          <Outlet />
          {/* layout children ends here */}
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
