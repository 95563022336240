import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Moment } from "moment";

import { getMonthName, getYear } from "../../helpers/date";

const CalenderCard = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "1px solid white",
  borderBottom: `4px solid white`,
  borderRadius: 12,
  overflow: "hidden",
  cursor: "pointer",
}));

const CalenderYear = styled(Box)(() => ({
  backgroundImage: "linear-gradient(to right, #3a9bc2, #0d4f6d)",
  padding: "0 0.8rem",
  fontSize: "0.8rem",
}));

const CalenderMonth = styled(Box)(() => ({
  fontSize: "1.1rem",
}));

interface Props {
  showAlways: boolean;
  date: Moment;
  onClick?: (date: Moment) => void;
}

const MonthCard: React.FC<Props> = ({ showAlways, date, onClick }) => {
  const sx = showAlways
    ? { display: "flex", boxShadow: "2px 5px 10px 2px rgba(197,133,23,0.7)" }
    : { display: { xs: "none", sm: "flex" } };

  const year = getYear(date);
  const month = getMonthName(date);

  const btnClickHandler = () => {
    onClick && onClick(date);
  };

  return (
    <CalenderCard component="div" sx={sx} onClick={btnClickHandler}>
      <CalenderYear>{year}</CalenderYear>
      <CalenderMonth>{month}</CalenderMonth>
    </CalenderCard>
  );
};

export default MonthCard;
