import moment, { Moment } from "moment";
import { getDueDays, isFuture } from "../helpers/date";
import { getDueMsg } from "../helpers/utils";

class AllTxns {
  id: string;
  name: string;
  amount: number;
  status: string;
  balance: number;
  remaining: string | null;
  date: Moment;
  type: string;
  key: string;
  bank: string;

  constructor(
    id: string,
    name: string,
    amount: number,
    date: Moment,
    type: string,
    key: string,
    bank: string,
    balance: number = 0,
    remaining: string | null = null
  ) {
    this.id = id;
    this.name = name;
    this.amount = amount;
    this.date = moment(date);
    this.status =
      type === "opening"
        ? ""
        : isFuture(date)
        ? getDueMsg(getDueDays(date))
        : "Cleared";
    this.type = type;
    this.key = key;
    this.bank = bank;
    this.balance = balance;
    this.remaining = remaining;
  }
}

export default AllTxns;
