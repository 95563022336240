import { NavLink } from "react-router-dom";

import Button from "@mui/material/Button";

import SettingsIcon from "@mui/icons-material/Settings";

const SettingsLink = () => {
  return (
    <NavLink
      to="/settings"
      className={({ isActive, isPending }) =>
        isPending ? "pending" : isActive ? "active" : ""
      }
    >
      <Button variant="text" color="secondary" startIcon={<SettingsIcon />}>
        Settings
      </Button>
    </NavLink>
  );
};

export default SettingsLink;
