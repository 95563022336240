import { NavLink } from "react-router-dom";

import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";

interface Props {
  pageTitle: string;
}

const BreadCrumb: React.FC<Props> = ({ pageTitle }) => {
  const Icon = pageTitle === "Profile" ? AccountCircleIcon : SettingsIcon;

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ pb: 2 }}>
      <NavLink
        to="/"
        style={{
          color: "inherit",
          textDecoration: "none",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="small" />
          Dashboard
        </Box>
      </NavLink>
      <Typography
        sx={{ display: "flex", alignItems: "center" }}
        color="text.primary"
      >
        <Icon sx={{ mr: 0.5 }} fontSize="small" />
        {pageTitle}
      </Typography>
    </Breadcrumbs>
  );
};

export default BreadCrumb;
