import CssBaseline from "@mui/material/CssBaseline";
import BasePage from "./routes/BasePage";
import AuthContextProvider from "./store/auth-context";

const App = () => (
  <AuthContextProvider>
    <CssBaseline />
    <BasePage />
  </AuthContextProvider>
);

export default App;
