import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import AmountIcon from "../UI/AmountIcon";
import { content_xs } from "../../helpers/utils";

interface Props {
  title: string;
  amount: number;
  balance?: number;
  onClick: () => void;
}

const CreditCardItem: React.FC<Props> = ({
  title,
  amount,
  balance,
  onClick,
}) => {
  return (
    <ListItem disablePadding>
      <ListItemButton onClick={onClick}>
        <ListItemText
          primary={
            <Typography variant="h6" component="div" role="label">
              {title}
            </Typography>
          }
        />
        <ListItemText
          primary={
            <Typography
              variant="h6"
              component="div"
              role="balance"
              sx={content_xs}
            >
              <AmountIcon amount={amount} />
            </Typography>
          }
          secondary={
            balance && (
              <Typography
                variant="body2"
                component="div"
                role="amount"
                sx={{ ...content_xs, color: balance < 0 ? "red" : "green" }}
              >
                {balance}
              </Typography>
            )
          }
          sx={{ textAlign: "end" }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default CreditCardItem;
