import Alert, { AlertColor } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

interface Props {
  message: string;
  severity?: AlertColor;
  onClose: () => void;
}

const SuccessAlert: React.FC<Props> = ({ message, severity, onClose }) => {
  return (
    <Snackbar
      open={true}
      autoHideDuration={5000}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={onClose}
        elevation={6}
        variant="filled"
        severity={severity || "success"}
        color={severity || "success"}
        sx={{ fontWeight: "bold" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SuccessAlert;
