import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import ProfileCard from "./ProfileCard";
import ProfileForm from "./ProfileForm";
import ProfilePassword from "./ProfilePassword";

function ProfileSection() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="start"
        spacing={1}
      >
        <Grid item xs={12} md={6}>
          <Paper variant="outlined" sx={{ p: 1 }}>
            <ProfileCard />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <Paper variant="outlined">
              <ProfileForm />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper variant="outlined">
              <ProfilePassword />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProfileSection;
