import { ReactNode } from "react";

import { styled } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";

interface Props {
  children?: ReactNode;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
}));

const FormModal: React.FC<Props> = ({ children }) => (
  <BootstrapDialog aria-labelledby="customized-dialog-title" open={true}>
    {children}
  </BootstrapDialog>
);

export default FormModal;
