import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Dashboard from "../components/Dashboard";
import AuthLayout from "../components/Layout/AuthLayout";
import MainLayout from "../components/Layout/MainLayout";
import Login from "../components/Auth/Login";
import ProtectedRoute from "./ProtectedRoute";
import NonProtectedRoute from "./NonProtectedRoute";
import ProfileSection from "../components/Profile/ProfileSection";
import SettingsSection from "../components/Settings/SettingsSection";
import ProfileLayout from "../components/Layout/ProfileLayout";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <MainLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "profile",
        element: <ProfileLayout />,
        children: [
          {
            index: true,
            element: <ProfileSection />,
          },
        ],
      },
      {
        path: "settings",
        element: <ProfileLayout />,
        children: [
          {
            index: true,
            element: <SettingsSection />,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: (
      <NonProtectedRoute>
        <AuthLayout />
      </NonProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <Login />,
      },
    ],
  },
]);

const BasePage = () => {
  return <RouterProvider router={router} />;
};

export default BasePage;
