class Credit {
  id: string;
  billed: number;
  debit: number;
  credit: number;
  loansTotal: number;
  creditTotal: number;
  balance: number;

  constructor(
    id: string,
    loansTotal?: number,
    billed?: number,
    debit?: number,
    credit?: number
  ) {
    const billedAmt = billed ? billed : 0;
    const debitAmt = debit ? debit : 0;
    const loansTotalAmt = loansTotal ? loansTotal : 0;
    const creditAmt = credit ? credit : 0;

    const creditTotal = billedAmt + debitAmt + loansTotalAmt - creditAmt;
    const balance = creditAmt - billedAmt;

    this.id = id;
    this.billed = billedAmt;
    this.debit = debitAmt;
    this.credit = creditAmt;
    this.loansTotal = loansTotalAmt;
    this.creditTotal = creditTotal;
    this.balance = balance;
  }
}

export default Credit;
