import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import HomeIcon from "@mui/icons-material/Home";
import MoneyIcon from "@mui/icons-material/Money";

interface Props {
  value: number;
  onChangeTab: (event: React.SyntheticEvent, newValue: number) => void;
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const TransactionTabs: React.FC<Props> = ({ value, onChangeTab }) => {
  return (
    <AppBar position="static">
      <Tabs
        value={value}
        onChange={onChangeTab}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        {tabsList.map((tab) => (
          <Tab
            key={tab.id}
            icon={<tab.Icon />}
            label={tab.name}
            {...a11yProps(0)}
          />
        ))}
      </Tabs>
    </AppBar>
  );
};

export default TransactionTabs;

const tabsList = [
  {
    id: 0,
    name: "All",
    Icon: HomeIcon,
  },
  {
    id: 1,
    name: "Income",
    Icon: AccountBalanceWalletIcon,
  },
  {
    id: 2,
    name: "Expense",
    Icon: MoneyIcon,
  },
  {
    id: 3,
    name: "Loans",
    Icon: AccountBalanceIcon,
  },
];
