import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import DialogContent from "@mui/material/DialogContent";

import Confirm from "../UI/Confirm";
import AmountInput from "../UI/Fields/AmountInput";
import DateInput from "../UI/Fields/DateInput";
import NameInput from "../UI/Fields/NameInput";
import FormActions from "../UI/FormActions";
import FormHeader from "../UI/FormHeader";
import FormModal from "../UI/FormModal";

import { useHome } from "../../store/home-context";

import { getDateWithTime } from "../../helpers/date";
import { FormValues } from "../../helpers/types";
import {
  validateAmount,
  validateName,
  validatePeriod,
} from "../../helpers/utils";
import { CreditLoan } from "../../models";
import { useCredit } from "../../store/credit-context";

type Props = {
  editItem: CreditLoan | null;
  onClose: () => void;
};

const CreditLoanForm: React.FC<Props> = ({ editItem, onClose }) => {
  const { selectedDate } = useHome();
  const { modifyCreditLoan, removeCreditLoan } = useCredit();

  const {
    control,
    formState: { isSubmitting, isDirty, isValid },
    handleSubmit,
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      txnName: "",
      txnAmount: 0,
      txnDate: getDateWithTime(selectedDate),
      txnPeriod: 1,
    },
    mode: "onChange",
  });

  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  useEffect(() => {
    if (!editItem) {
      setValue("txnDate", getDateWithTime(selectedDate), { shouldDirty: true });
    }
  }, [selectedDate, editItem, setValue]);

  useEffect(() => {
    if (editItem) {
      const loanItem = editItem as CreditLoan;
      setValue("txnName", editItem.name);
      setValue("txnAmount", editItem.amount);
      setValue("txnDate", getDateWithTime(loanItem.start_date));
      setValue("txnPeriod", loanItem.period);
    }
  }, [editItem, setValue]);

  const dateChangeHandler = useCallback(
    (value: Moment | null | undefined) => {
      setValue("txnDate", getDateWithTime(value), { shouldDirty: true });
    },
    [setValue]
  );

  const deleteTxnHandler = useCallback(() => {
    setShowConfirm(true);
  }, []);

  const confirmDeleteHandler = useCallback(() => {
    if (editItem) {
      removeCreditLoan(editItem.id);
    }
    setShowConfirm(false);
    onClose();
  }, [editItem, onClose, removeCreditLoan]);

  const closeConfirmHandler = useCallback(() => {
    setShowConfirm(false);
  }, []);

  const onSubmit = useCallback(
    async (data: FormValues) => {
      if (!isValid) {
        return false;
      }

      await modifyCreditLoan(data, editItem?.id);
      onClose();
    },
    [editItem, isValid, modifyCreditLoan, onClose]
  );

  return (
    <FormModal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormHeader
          title={`${editItem ? "Modify" : "Add"} Credit Loan`}
          onClose={onClose}
        />
        <DialogContent dividers>
          <Controller
            control={control}
            name="txnName"
            rules={{
              required: "Name is required!",
              validate: validateName,
            }}
            render={({
              field: { name, onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <NameInput
                onChange={onChange}
                onBlur={onBlur}
                fieldValue={value}
                fieldName={name}
                fieldLabel="Name"
                error={error?.message}
                fieldType="text"
              />
            )}
          />
          <Controller
            control={control}
            name="txnAmount"
            rules={{
              required: "Amount is required!",
              validate: validateAmount,
            }}
            render={({
              field: { name, onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <AmountInput
                onChange={onChange}
                onBlur={onBlur}
                fieldValue={value}
                fieldName={name}
                fieldLabel="Amount"
                error={error?.message}
                fieldType="number"
              />
            )}
          />
          <Controller
            control={control}
            name="txnDate"
            rules={{
              required: "Date is required.",
            }}
            render={({
              field: { name, onBlur, value },
              fieldState: { error },
            }) => (
              <DateInput
                onChange={(value: Moment | null | undefined) =>
                  dateChangeHandler(value)
                }
                onBlur={onBlur}
                fieldValue={value}
                fieldName={name}
                fieldLabel="Date"
                error={error?.message}
                fieldType="text"
              />
            )}
          />
          <Controller
            control={control}
            name="txnPeriod"
            rules={{
              required: "Period is required.",
              validate: validatePeriod,
            }}
            render={({
              field: { name, onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <NameInput
                onChange={onChange}
                onBlur={onBlur}
                fieldValue={value}
                fieldName={name}
                fieldLabel="Period"
                error={error?.message}
                fieldType="number"
              />
            )}
          />
        </DialogContent>
        <FormActions
          showDelete={!!editItem}
          disabled={isSubmitting || !isDirty || !isValid}
          onClose={onClose}
          onDelete={deleteTxnHandler}
        />
      </form>
      {showConfirm && (
        <Confirm
          onAccept={confirmDeleteHandler}
          onClose={closeConfirmHandler}
        />
      )}
    </FormModal>
  );
};

export default CreditLoanForm;
