import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { getUserRef } from "../config/firebase";
import {
  creditDateKey,
  getLoanEndDate,
  getStartEndTime,
} from "../helpers/date";
import { CreditFormValues, CreditLoanFormValues } from "../helpers/types";
import { creditInit, filterCreditLoans, filterLoans } from "../helpers/utils";
import { Credit, CreditLoan } from "../models";
import { useAuth } from "./auth-context";
import { useHome } from "./home-context";

export interface CreditProviderProps {
  children?: ReactNode;
}

type IdType = string | undefined;

export interface CreditContextModel {
  credit: Credit;
  creditLoans: CreditLoan[];
  isLoading: boolean;
  successMsg: string | null;
  modifyCredit: (data: CreditFormValues) => Promise<void>;
  modifyCreditLoan: (
    data: CreditLoanFormValues,
    loanId: IdType
  ) => Promise<void>;
  removeCreditLoan: (loanId: IdType) => Promise<void>;
  onCloseAlert: () => void;
}

const ccLoansTotal = 0;
const CREDIT_KEY = "credit";
const CREDIT_LOANS_KEY = "credit_loans";

export const CreditContext = createContext<CreditContextModel>(
  {} as CreditContextModel
);

const CreditContextProvider = ({
  children,
}: CreditProviderProps): JSX.Element => {
  const { selectedDate } = useHome();
  const { userId } = useAuth();

  const [credit, setCredit] = useState<Credit>(creditInit(selectedDate));
  const [creditLoans, setCreditLoans] = useState<CreditLoan[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState<string | null>(null);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (successMsg) {
      timer = setTimeout(() => {
        setSuccessMsg(null);
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [successMsg]);

  useEffect(() => {
    async function getCreditData() {
      const startEndTime = getStartEndTime(selectedDate);
      const userRef = getUserRef(userId);
      const dbCreditLoans: CreditLoan[] = [];
      const ccLnRef = collection(userRef, CREDIT_LOANS_KEY);
      const lnQuery = query(
        ccLnRef,
        where("end_date", ">=", startEndTime.start),
        orderBy("end_date", "desc")
      );
      const lnQuerySnapshot = await getDocs(lnQuery);
      lnQuerySnapshot.forEach((doc) => {
        const ccItemData = doc.data();
        dbCreditLoans.push(
          new CreditLoan(
            doc.id,
            ccItemData.name,
            +ccItemData.amount,
            ccItemData.start_date,
            ccItemData.end_date,
            ccItemData.period,
            selectedDate
          )
        );
      });

      let dbCredit: Credit;
      const creditId = creditDateKey(selectedDate);
      const creditDocRef = doc(userRef, CREDIT_KEY, creditId);
      const creditDocSnap = await getDoc(creditDocRef);
      if (creditDocSnap.exists()) {
        const creditDocData = creditDocSnap.data();
        dbCredit = new Credit(
          creditId,
          0,
          +creditDocData.billed,
          +creditDocData.debit,
          +creditDocData.credit
        );
      } else {
        dbCredit = creditInit(selectedDate, 0);
      }

      setCredit(dbCredit);
      setCreditLoans(filterLoans(dbCreditLoans, selectedDate));
      setIsLoading(false);
    }
    if (userId) {
      setIsLoading(true);
      getCreditData();
    }
  }, [selectedDate, userId]);

  const modifyCreditHandler = useCallback(
    async (data: CreditFormValues) => {
      try {
        const id = creditDateKey(selectedDate);
        const userRef = getUserRef(userId);
        const dbItem = {
          billed: +data.ccBilled,
          debit: +data.ccDebit,
          credit: +data.ccCredit,
        };

        const docRef = doc(userRef, CREDIT_KEY, id);
        await setDoc(docRef, dbItem);
        const ccItem = new Credit(
          id,
          ccLoansTotal,
          +data.ccBilled,
          +data.ccDebit,
          +data.ccCredit
        );

        setCredit(ccItem);
        setSuccessMsg(`Credit Amounts Updated Successfully!`);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    },
    [selectedDate, userId]
  );

  const modifyCreditLoanHandler = useCallback(
    async (data: CreditLoanFormValues, loanId: IdType) => {
      const endDate = getLoanEndDate(data.txnDate, data.txnPeriod);
      const userRef = getUserRef(userId);
      const lnItem = {
        name: data.txnName,
        amount: data.txnAmount,
        start_date: data.txnDate,
        end_date: endDate,
        period: data.txnPeriod,
      };
      if (!loanId) {
        try {
          const addLnRef = await addDoc(
            collection(userRef, CREDIT_LOANS_KEY),
            lnItem
          );
          setCreditLoans((curLoans) => {
            const newLoans = [...curLoans];
            newLoans.push(
              new CreditLoan(
                addLnRef.id,
                data.txnName,
                +data.txnAmount,
                data.txnDate,
                endDate,
                data.txnPeriod,
                selectedDate
              )
            );
            return filterCreditLoans(newLoans, selectedDate);
          });
          setSuccessMsg("Credit Loan Added Successfully!");
        } catch (error) {}
      } else {
        try {
          const editLnRef = doc(userRef, CREDIT_LOANS_KEY, loanId);
          await updateDoc(editLnRef, lnItem);
          setCreditLoans((curLoans) => {
            const newLoans = [...curLoans];
            const index = newLoans.findIndex((ln) => ln.id === loanId);
            newLoans[index] = new CreditLoan(
              loanId,
              data.txnName,
              +data.txnAmount,
              data.txnDate,
              endDate,
              data.txnPeriod,
              selectedDate
            );
            return filterCreditLoans(newLoans, selectedDate);
          });
          setSuccessMsg("Credit Loan Updated Successfully!");
        } catch (error) {}
      }
    },
    [selectedDate, userId]
  );

  const removeCreditLoanHandler = useCallback(
    async (loanId: IdType) => {
      if (loanId) {
        setIsLoading(false);
        try {
          const userRef = getUserRef(userId);
          await deleteDoc(doc(userRef, CREDIT_LOANS_KEY, loanId));
          setCreditLoans((curLoans) =>
            curLoans.filter((ln) => ln.id !== loanId)
          );
          setSuccessMsg(`Credit Loan Deleted Successfully!`);
          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
          // setIsError(err.message || "Something went wrong!");
        }
      }
    },
    [userId]
  );

  const handleAlertClose = useCallback(() => setSuccessMsg(null), []);

  const values: CreditContextModel = useMemo(
    () => ({
      credit,
      creditLoans,
      isLoading,
      successMsg,
      modifyCredit: modifyCreditHandler,
      modifyCreditLoan: modifyCreditLoanHandler,
      removeCreditLoan: removeCreditLoanHandler,
      onCloseAlert: handleAlertClose,
    }),
    [
      isLoading,
      credit,
      creditLoans,
      successMsg,
      modifyCreditHandler,
      modifyCreditLoanHandler,
      removeCreditLoanHandler,
      handleAlertClose,
    ]
  );

  return (
    <CreditContext.Provider value={values}>{children}</CreditContext.Provider>
  );
};

export default CreditContextProvider;

export function useCredit(): CreditContextModel {
  return useContext(CreditContext);
}
