import { NavLink } from "react-router-dom";

import Button from "@mui/material/Button";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const ProfileLink = () => {
  return (
    <NavLink
      to="/profile"
      className={({ isActive, isPending }) =>
        isPending ? "pending" : isActive ? "active" : ""
      }
    >
      <Button
        variant="text"
        color="secondary"
        startIcon={<AccountCircleIcon />}
      >
        Profile
      </Button>
    </NavLink>
  );
};

export default ProfileLink;
