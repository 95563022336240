import { useMemo } from "react";
import { Cell, Pie, PieChart } from "recharts";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { checkDateIsGreater, remainingDays } from "../../helpers/date";
import { useHome } from "../../store/home-context";
import { formatCurrency } from "../../helpers/utils";

interface Props {
  balance: number | undefined;
  incomesTotal: number;
}

const SummaryChart: React.FC<Props> = ({ balance, incomesTotal }) => {
  const { selectedDate } = useHome();

  const balanceAmount = balance ? balance : 0;
  const needleValue =
    incomesTotal === 0
      ? 0
      : ((incomesTotal - balanceAmount) * 100) / incomesTotal;

  const value = needleValue > 100 ? 100 : needleValue < 0 ? 0 : needleValue;

  const isGreater = useMemo(
    () => checkDateIsGreater(selectedDate),
    [selectedDate]
  );

  let chartText = ``;
  if (isGreater === "great") {
    chartText = `You will have Rs ${formatCurrency(
      balanceAmount
    )} remaining funds on this month.`;
  } else if (isGreater === "less") {
    chartText = `You had Rs ${formatCurrency(
      balanceAmount
    )} remaining funds on this month.`;
  } else {
    chartText = `You have Rs ${formatCurrency(
      balanceAmount
    )} remaining funds over the next ${remainingDays} ${
      remainingDays <= 1 ? "day" : "days"
    }.`;
  }

  return (
    <Grid
      item
      xs
      sx={{
        width: "100%",
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderBottom: `1px solid rgba(255, 255, 255, 0.12)`,
      }}
    >
      <PieChart width={300} height={150}>
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          fill="#8884d8"
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        {needle(value, data, cx, cy, iR, oR, "#3a9bc2")}
      </PieChart>
      <Box
        sx={{
          textAlign: "center",
          mr: 5,
          pr: 2,
          ml: 5,
          mb: 2,
          pl: 2,
        }}
      >
        <Typography>{chartText}</Typography>
      </Box>
    </Grid>
  );
};

export default SummaryChart;

interface ChartData {
  name: string;
  value: number;
  color: string;
}

const RADIAN = Math.PI / 180;
const data = [
  { name: "A", value: 50, color: "#1f571e" },
  { name: "B", value: 25, color: "#af7114" },
  { name: "C", value: 25, color: "#5c0808" },
];
const cx = 150;
const cy = 120;
const iR = 50;
const oR = 100;

const needle = (
  value: number,
  data: ChartData[],
  cx: number,
  cy: number,
  iR: number,
  oR: number,
  color: string
) => {
  let total = 0;
  data.forEach((v) => {
    total += v.value;
  });
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle
      cx={x0}
      cy={y0}
      r={r}
      fill={color}
      stroke="none"
      key={`circle-${x0}-${y0}`}
    />,
    <path
      d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
      stroke="#none"
      fill={color}
      key={`path-${x0}-${y0}`}
    />,
  ];
};
