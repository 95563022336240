import { useCallback, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";

import SaveIcon from "@mui/icons-material/Save";

import { red } from "@mui/material/colors";
import { validatePassword } from "../../helpers/utils";
import { useAuth } from "../../store/auth-context";
import PasswordInput from "../UI/Fields/PasswordInput";
import SuccessAlert from "../UI/SuccessAlert";

interface IFormInputs {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const ProfilePassword = () => {
  const { successMsg, onChangePassword, onCloseAlert } = useAuth();

  const [formError, setFormError] = useState<string | null>(null);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
    setError,
  } = useForm<IFormInputs>({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    mode: "onChange",
  });

  const closeAlertHandler = useCallback(() => setFormError(null), []);

  const onSubmit: SubmitHandler<IFormInputs> = useCallback(
    (data) => {
      setFormError(null);
      const { currentPassword, newPassword, confirmNewPassword } = data;

      if (!isValid) {
        return false;
      }

      if (newPassword.trim() !== confirmNewPassword.trim()) {
        setError("confirmNewPassword", {
          type: "manual",
          message: "Password Does Not Match!",
        });
        // setFormError("Password Does Not Match!");
        return false;
      }

      try {
        onChangePassword(currentPassword, newPassword);
        setFormError(null);
        reset({ currentPassword: "", newPassword: "", confirmNewPassword: "" });
      } catch (error) {
        setFormError("Password update failed");
        // console.log("submit error", error);
      }
    },
    [isValid, onChangePassword, reset, setError]
  );

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 2,
      }}
    >
      <Card sx={{ width: "100%" }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              R
            </Avatar>
          }
          title="Password Settings"
        />
        <CardContent
          sx={{
            "&:last-child": {
              pb: 1,
            },
          }}
        >
          <Box sx={{ mb: 2 }}>
            <Controller
              name="currentPassword"
              control={control}
              rules={{
                required: "Current Password is required!",
                validate: validatePassword,
              }}
              render={({
                field: { name, value, onChange, onBlur, ref },
                fieldState: { error },
              }) => (
                <PasswordInput
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldValue={value}
                  fieldName={name}
                  fieldLabel="Current Password"
                  error={error?.message}
                />
              )}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Controller
              name="newPassword"
              control={control}
              rules={{
                required: "Password is required!",
                validate: validatePassword,
              }}
              render={({
                field: { name, value, onChange, onBlur, ref },
                fieldState: { error },
              }) => (
                <PasswordInput
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldValue={value}
                  fieldName={name}
                  fieldLabel="New Password"
                  error={error?.message}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name="confirmNewPassword"
              control={control}
              rules={{
                required: "Confirm Password is required!",
                validate: validatePassword,
              }}
              render={({
                field: { name, value, onChange, onBlur, ref },
                fieldState: { error },
              }) => (
                <PasswordInput
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldValue={value}
                  fieldName={name}
                  fieldLabel="Confirm New Password"
                  error={error?.message}
                />
              )}
            />
          </Box>
        </CardContent>
        <CardActions disableSpacing sx={{ justifyContent: "center" }}>
          <Stack spacing={2} direction="row">
            {/* <Button variant="text" size="large" color="inherit" fullWidth>
                Cancel
              </Button> */}
            <Button
              type="submit"
              variant="outlined"
              size="large"
              color="secondary"
              disabled={isSubmitting || !isDirty || !isValid}
              fullWidth
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Stack>
        </CardActions>
      </Card>
      {!!formError && (
        <SuccessAlert
          message={formError}
          severity="error"
          onClose={closeAlertHandler}
        />
      )}
      {!!successMsg && (
        <SuccessAlert message={successMsg} onClose={onCloseAlert} />
      )}
    </Box>
  );
};

export default ProfilePassword;
