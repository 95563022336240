import moment, { Moment } from "moment";
import { getDbDateKey, getLoanDate, getRemainingMonths } from "../helpers/date";
import { getRemainingMsg } from "../helpers/utils";

class Loan {
  id: string;
  name: string;
  amount: number;
  start_date: Moment;
  end_date: Moment;
  period: number;
  date: Moment;
  type: string;
  key: string;
  bank: string;
  remaining?: string | null;

  constructor(
    id: string,
    name: string,
    amount: number,
    start_date: string,
    end_date: string,
    period: number,
    bank: string,
    date: Moment
  ) {
    const remaining = getRemainingMonths(moment(end_date), date);
    const remainingMsg = getRemainingMsg(remaining, period);

    this.id = id;
    this.name = name;
    this.amount = amount;
    this.start_date = moment(start_date);
    this.end_date = moment(end_date);
    this.period = period;
    this.bank = bank;
    this.type = "loan";
    this.date = getLoanDate(moment(start_date), moment(date));
    this.key = getDbDateKey(date); //calculate key from date
    this.remaining = remainingMsg; //calculate remaining months
  }
}

export default Loan;
