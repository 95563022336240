import { useCallback, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import TabPanel from "../UI/TabPanel";
import TransactionForm from "./TransactionForm";
import TransactionHeader from "./TransactionHeader";
import TransactionList from "./TransactionList";

import { EditType } from "../../helpers/types";
import { filterByDate, filterTxnsByType } from "../../helpers/utils";
import { AllTxns } from "../../models";
import { useTxns } from "../../store/txns-context";
import SuccessAlert from "../UI/SuccessAlert";
import Loader from "../UI/Loader";

const TransactionTable = () => {
  const [tab, setTab] = useState(0);
  const [openForm, setOpenForm] = useState(false);
  const [filterItem, setFilterITem] = useState("all");
  const [editItem, setEditItem] = useState<EditType>(null);
  const [anchorFilterEl, setAnchorFilterEl] = useState<null | HTMLElement>(
    null
  );

  const filterIsOpen = Boolean(anchorFilterEl);

  const { allTxns, txns, opening, loans, isLoading, successMsg, onCloseAlert } =
    useTxns();

  const tabChangeHandler = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const filterButtonClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorFilterEl(event.currentTarget);
  };

  const filterMenuClose = () => {
    setAnchorFilterEl(null);
  };

  const filterItemClickHandler = (item: string) => {
    setFilterITem(item);
  };

  const handleFormOpen = useCallback(() => setOpenForm(true), []);
  const handleFormClose = useCallback(() => {
    setOpenForm(false);
    setEditItem(null);
  }, []);

  const itemSelectHandler = useCallback(
    (item: AllTxns) => {
      let eItem: EditType;
      if (item.type === "loan") {
        eItem = loans.find((ln) => ln.id === item.id);
      } else if (item.type === "opening") {
        eItem = opening.find((opn) => opn.id === item.id);
      } else {
        eItem = txns.find((tx) => tx.id === item.id && tx.type === item.type);
      }
      setEditItem(eItem);
      setOpenForm(true);
    },
    [txns, loans, opening]
  );

  const tableData = useMemo(() => {
    switch (tab) {
      case 1:
        return filterTxnsByType(allTxns, ["income", "opening"]);
      case 2:
        return filterTxnsByType(allTxns, ["expense"]);
      case 3:
        return filterTxnsByType(allTxns, ["loan"]);
      default:
        return filterByDate(allTxns, filterItem);
    }
  }, [tab, allTxns, filterItem]);

  return (
    <>
      <TransactionHeader
        value={tab}
        filterItem={filterItem}
        filterIsOpen={filterIsOpen}
        onChangeTab={tabChangeHandler}
        onClickFilterButton={filterButtonClickHandler}
        onOpenForm={handleFormOpen}
      />
      <Grid
        item
        xs
        sx={{
          border: `1px solid rgba(255, 255, 255, 0.12)`,
          overflowY: "auto",
          minHeight: "400px",
        }}
      >
        <Box sx={{ width: "100%", height: "100%" }}>
          {isLoading && <Loader />}
          {!isLoading && (
            <TabPanel value={0} index={0}>
              <TransactionList
                items={tableData}
                tab={tab}
                onSelectItem={itemSelectHandler}
              />
            </TabPanel>
          )}
        </Box>
      </Grid>
      {openForm && (
        <TransactionForm
          tab={tab}
          editItem={editItem}
          onClose={handleFormClose}
        />
      )}
      <Menu
        anchorEl={anchorFilterEl}
        id="account-menu"
        open={filterIsOpen}
        onClose={filterMenuClose}
        onClick={filterMenuClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={filterItemClickHandler.bind(this, "all")}>
          All
        </MenuItem>
        <MenuItem onClick={filterItemClickHandler.bind(this, "pending")}>
          Pending
        </MenuItem>
        <MenuItem onClick={filterItemClickHandler.bind(this, "cleared")}>
          Cleared
        </MenuItem>
      </Menu>
      {!!successMsg && (
        <SuccessAlert message={successMsg} onClose={onCloseAlert} />
      )}
    </>
  );
};

export default TransactionTable;
