import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { doc, getDoc, setDoc } from "firebase/firestore";

import { getUserRef } from "../config/firebase";
import { banksList } from "../helpers/banks";
import { defaultDate, getNextMonth, getPrevMonth } from "../helpers/date";
import { ActiveBanks, Dates } from "../helpers/types";
import { Bank } from "../models";
import { useAuth } from "./auth-context";

interface HomeProviderProps {
  children?: ReactNode;
}

interface HomeContextModel {
  banksList: Bank[];
  isLoading: boolean;
  selectedDate: Dates;
  activeBanks: ActiveBanks;
  favBank: string;
  selectedBank: string;
  successMsg: string | null;
  onSelectMonth: (date: Dates) => void;
  onPrevMonth: () => void;
  onNextMonth: () => void;
  onSelectBank: (bank: string) => void;
  onSelectFavBank: (bank: string) => void;
  onChangeBank: (bank: string) => void;
  onSaveBankSettings: (enabledBanks: ActiveBanks, defaultBank: string) => void;
  onCloseAlert: () => void;
}

const HomeContext = createContext<HomeContextModel>({} as HomeContextModel);

const CONTROLLER_KEY = "userData";
const KEY_ID1 = "banks";

const HomeContextProvider = ({ children }: HomeProviderProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState<Dates>(defaultDate);
  const [activeBanks, setActiveBanks] = useState<ActiveBanks>({});
  const [favBank, setFavBank] = useState<string>("");
  const [selectedBank, setSelectedBank] = useState<string>("");
  const [successMsg, setSuccessMsg] = useState<string | null>(null);

  const { userId } = useAuth();

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (successMsg) {
      timer = setTimeout(() => {
        setSuccessMsg(null);
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [successMsg]);

  useEffect(() => {
    setIsLoading(true);
    async function getBankList() {
      const userRef = getUserRef(userId);
      const docRef = doc(userRef, CONTROLLER_KEY, KEY_ID1);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const docData = docSnap.data();
        const curBanks: ActiveBanks = {};
        let defaultBank = "";
        for (let key in docData) {
          if (key === "default") {
            defaultBank = docData[key];
          } else {
            curBanks[key] = docData[key];
          }
        }
        setActiveBanks(curBanks);
        setFavBank(defaultBank);
        setSelectedBank(defaultBank);
        setIsLoading(false);
      } else {
        // docSnap.data() will be undefined in this case
        // console.log("No such document!");
        setIsLoading(false);
      }
    }
    getBankList();
  }, [userId]);

  const previousMonthHandler = useCallback(() => {
    setSelectedDate((curDate) => getPrevMonth(curDate));
  }, []);

  const nextMonthHandler = useCallback(() => {
    setSelectedDate((curDate) => getNextMonth(curDate));
  }, []);

  const selectMonthHandler = useCallback((date: Dates) => {
    setSelectedDate(date);
  }, []);

  const selectBankHandler = useCallback((bank: string) => {
    setActiveBanks((curBanks) => {
      return {
        ...curBanks,
        [bank]: !curBanks[bank],
      };
    });
  }, []);

  const selectFavBankHandler = useCallback((bank: string) => {
    setFavBank(bank);
  }, []);

  const changeBankHandler = useCallback((bank: string) => {
    setSelectedBank(bank);
  }, []);

  const saveBankSettingsHandler = useCallback(
    async (enabledBanks: ActiveBanks, defaultBank: string) => {
      setIsLoading(true);
      try {
        const userRef = getUserRef(userId);
        const enbBanks: ActiveBanks = {};
        for (let bnk in enabledBanks) {
          if (enabledBanks[bnk]) {
            enbBanks[bnk] = enabledBanks[bnk];
          }
        }
        const data = {
          ...enbBanks,
          default: defaultBank,
        };
        const docRef = doc(userRef, CONTROLLER_KEY, KEY_ID1);
        await setDoc(docRef, data);
        setActiveBanks(enabledBanks);
        setFavBank(defaultBank);
        setSelectedBank(defaultBank);
        setSuccessMsg(`Bank Settings Updated Successfully!`);
        setIsLoading(false);
      } catch (err) {
        console.log("items fetch error custom", err);
        setIsLoading(false);
      }
    },
    [userId]
  );

  const handleAlertClose = useCallback(() => setSuccessMsg(null), []);

  const values: HomeContextModel = useMemo(
    () => ({
      banksList,
      selectedDate,
      isLoading,
      activeBanks,
      favBank,
      selectedBank,
      successMsg,
      onSelectMonth: selectMonthHandler,
      onPrevMonth: previousMonthHandler,
      onNextMonth: nextMonthHandler,
      onSelectBank: selectBankHandler,
      onSelectFavBank: selectFavBankHandler,
      onChangeBank: changeBankHandler,
      onSaveBankSettings: saveBankSettingsHandler,
      onCloseAlert: handleAlertClose,
    }),
    [
      isLoading,
      selectedDate,
      activeBanks,
      favBank,
      selectedBank,
      successMsg,
      selectMonthHandler,
      previousMonthHandler,
      nextMonthHandler,
      selectBankHandler,
      selectFavBankHandler,
      changeBankHandler,
      saveBankSettingsHandler,
      handleAlertClose,
    ]
  );

  return <HomeContext.Provider value={values}>{children}</HomeContext.Provider>;
};

export default HomeContextProvider;

export function useHome(): HomeContextModel {
  return useContext(HomeContext);
}
