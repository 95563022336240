import moment, { Moment } from "moment";
import { forwardRef, useCallback, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import { TransitionProps } from "@mui/material/transitions";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

import { getNextMonth, getPrevMonth } from "../../helpers/date";
import { useHome } from "../../store/home-context";
import DateLocalize from "./Fields/DateLocalize";
import MonthCard from "./MonthCard";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Dates = () => {
  const { selectedDate, onSelectMonth, onNextMonth, onPrevMonth } = useHome();

  const [open, setOpen] = useState(false);
  const [modalDate, setModalDate] = useState(selectedDate);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const prevMonth = getPrevMonth(selectedDate);
  const nextMonth = getNextMonth(selectedDate);

  const dateChangeHandler = useCallback(
    (value?: Moment | null) => {
      onSelectMonth(moment(value));
      handleClose();
    },
    [onSelectMonth, handleClose]
  );

  const yearChangeHandler = useCallback((value?: Moment | null) => {
    setModalDate(moment(value));
  }, []);

  return (
    <Grid
      item
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        mt: 1,
        mb: 1,
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <IconButton aria-label="previousmonth" onClick={onPrevMonth}>
          <ArrowCircleLeftOutlinedIcon />
        </IconButton>
        <MonthCard
          date={prevMonth}
          onClick={onSelectMonth}
          showAlways={false}
        />
        <Button color="inherit" onClick={handleClickOpen} sx={{ p: 0 }}>
          <MonthCard date={selectedDate} showAlways={true} />
        </Button>
        <MonthCard
          date={nextMonth}
          onClick={onSelectMonth}
          showAlways={false}
        />
        <IconButton aria-label="nextmonth" onClick={onNextMonth}>
          <ArrowCircleRightOutlinedIcon />
        </IconButton>
      </Stack>
      {open && (
        <Dialog
          autoFocus
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent sx={{ p: 1 }}>
            <DateLocalize>
              <StaticDatePicker
                openTo="month"
                views={["month", "year"]}
                value={moment(modalDate)}
                onMonthChange={dateChangeHandler}
                onYearChange={yearChangeHandler}
                slots={{
                  actionBar: () => null,
                  toolbar: () => null,
                }}
                sx={{ width: "100%" }}
              />
            </DateLocalize>
          </DialogContent>
        </Dialog>
      )}
    </Grid>
  );
};

export default Dates;
