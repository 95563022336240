import { useCallback, useState } from "react";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface Props {
  fieldName: string;
  fieldLabel: string;
  fieldValue: string | number;
  error: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  // ref: React.Ref<HTMLInputElement>;
}

const PasswordInput: React.FC<Props> = ({
  fieldName,
  fieldLabel,
  fieldValue,
  error,
  onBlur,
  onChange,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = useCallback(
    () => setShowPassword((show) => !show),
    []
  );

  const handleMouseDownPassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
    []
  );

  return (
    <FormControl fullWidth error={!!error} color="secondary">
      <InputLabel htmlFor={fieldName}>{fieldLabel}</InputLabel>
      <OutlinedInput
        id={fieldName}
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {!showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={fieldLabel}
        fullWidth
        name={fieldName}
        value={fieldValue}
        onChange={onChange}
        onBlur={onBlur}
        // inputRef={ref}
      />
      {!!error && (
        <FormHelperText id="password-error-text">{error}</FormHelperText>
      )}
    </FormControl>
  );
};
export default PasswordInput;
