import { useCallback, useMemo, useState } from "react";

import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import AddIcon from "@mui/icons-material/Add";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CreditCardIcon from "@mui/icons-material/CreditCard";

import AmountIcon from "../UI/AmountIcon";
import CreditCardForm from "./CreditCardForm";
import CreditCardLoans from "./CreditCardLoans";
import CreditCardOverview from "./CreditCardOverview";

import { getCreditCycle } from "../../helpers/date";
import { content_xs, getTotal } from "../../helpers/utils";
import { Credit, CreditLoan } from "../../models";
import { useCredit } from "../../store/credit-context";
import CreditLoanForm from "./CreditLoanForm";
import Loader from "../UI/Loader";

function CreditCardSection() {
  const [isOverview, setsIsOverview] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const [openLoanForm, setOpenLoanForm] = useState(false);
  const [editItem, setEditItem] = useState<CreditLoan | null>(null);

  const { credit, creditLoans, isLoading } = useCredit();

  const creditItem = useMemo(() => {
    const ccLoansTotal = getTotal(creditLoans);
    return new Credit(
      credit.id,
      ccLoansTotal,
      credit.billed,
      credit.debit,
      credit.credit
    );
  }, [credit, creditLoans]);

  const handleFormOpen = useCallback(() => setOpenForm(true), []);
  const handleFormClose = useCallback(() => setOpenForm(false), []);

  const handleLoanFormOpen = useCallback(() => setOpenLoanForm(true), []);
  const handleLoanFormClose = useCallback(() => {
    setOpenLoanForm(false);
    setEditItem(null);
  }, []);

  const handleSectionChange = (value: boolean) => {
    setsIsOverview(value);
  };

  const itemSelectHandler = useCallback((item: CreditLoan) => {
    setEditItem(item);
    setOpenLoanForm(true);
  }, []);

  const title = isOverview ? "Credit Cards" : "Credit Loans";
  const titleIcon = isOverview ? (
    <Avatar>
      <CreditCardIcon />
    </Avatar>
  ) : (
    <IconButton
      aria-label="back"
      onClick={handleSectionChange.bind(null, true)}
    >
      <ChevronLeftIcon />
    </IconButton>
  );
  const section = isOverview ? (
    <CreditCardOverview
      creditItem={creditItem}
      onSelectLoans={handleSectionChange}
      onSelectForm={handleFormOpen}
    />
  ) : (
    <CreditCardLoans items={creditLoans} onSelect={itemSelectHandler} />
  );
  const endItem = isOverview ? (
    <Chip
      label={
        <Typography variant="h6" component="div" role="balance" sx={content_xs}>
          <AmountIcon amount={creditItem.creditTotal} />
        </Typography>
      }
      color="success"
      variant="outlined"
    />
  ) : (
    <Tooltip title="Add Credit Loan">
      <IconButton edge="end" aria-label="add" onClick={handleLoanFormOpen}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      <AppBar position="static">
        <List dense={true} sx={{ flexGrow: 1, p: 0 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box sx={{ p: 1 }}>{titleIcon}</Box>
            <Box
              sx={{ flexGrow: 1, textAlign: "left", wordBreak: "break-all" }}
            >
              <Typography variant="body1" component="div" role="name">
                {title}
              </Typography>
              <Typography
                variant="caption"
                component="div"
                role="balance"
                sx={{ color: "#d8c9c9" }}
              >
                {getCreditCycle(credit.id)}
              </Typography>
            </Box>
            <Box sx={{ p: 1 }}>{endItem}</Box>
          </Box>
        </List>
      </AppBar>
      <Grid
        item
        xs
        sx={{
          border: `1px solid rgba(255, 255, 255, 0.12)`,
          overflowY: "auto",
          minHeight: "280px",
        }}
      >
        <Box sx={{ width: "100%", height: "100%" }}>
          {isLoading && <Loader />}
          {!isLoading && (
            <List
              sx={{
                flex: "1 1 100%",
                width: "100%",
              }}
            >
              {section}
            </List>
          )}
        </Box>
      </Grid>
      {openForm && (
        <CreditCardForm openForm={openForm} onClose={handleFormClose} />
      )}
      {openLoanForm && (
        <CreditLoanForm editItem={editItem} onClose={handleLoanFormClose} />
      )}
    </>
  );
}

export default CreditCardSection;
