import Box from "@mui/material/Box";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { PickerChangeHandlerContext } from "@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types";
import { DateValidationError } from "@mui/x-date-pickers/models";
import moment, { Moment } from "moment";
import DateLocalize from "./DateLocalize";
import { getStartEndTime } from "../../../helpers/date";

interface Props {
  fieldType: string;
  fieldName: string;
  fieldLabel: string;
  fieldValue: string;
  error: string | undefined;
  hasMinMax?: boolean;
  onChange:
    | ((
        value?: Moment | null,
        context?: PickerChangeHandlerContext<DateValidationError>
      ) => void)
    | undefined;
  onBlur: () => void;
  // ref: React.Ref<HTMLInputElement>;
}

const DateInput: React.FC<Props> = ({
  fieldType,
  fieldName,
  fieldLabel,
  fieldValue,
  error,
  hasMinMax,
  onBlur,
  onChange,
}) => {
  const startEnd = getStartEndTime(fieldValue);

  const dateChangeHandler = (value?: Moment | null) => {
    onChange && onChange(value);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <DateLocalize>
        <MobileDatePicker
          closeOnSelect
          format="MMM DD, YYYY"
          label={fieldLabel}
          value={moment(fieldValue)}
          onChange={dateChangeHandler}
          slots={{
            actionBar: () => null,
            toolbar: () => null,
          }}
          sx={{ width: "100%" }}
          minDate={!hasMinMax ? undefined : moment(startEnd.start)}
          maxDate={!hasMinMax ? undefined : moment(startEnd.end)}
        />
      </DateLocalize>
    </Box>
  );
};

export default DateInput;
