import { useMemo } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { getAllDatesInMonth, getChartDate, isFuture } from "../../helpers/date";
import { dataFormater } from "../../helpers/utils";
import { useHome } from "../../store/home-context";
import { useTxns } from "../../store/txns-context";

const TransactionChart = () => {
  const { selectedDate } = useHome();
  const { allTxns } = useTxns();

  const allDates = useMemo(
    () => getAllDatesInMonth(selectedDate),
    [selectedDate]
  );

  const chartData = useMemo(() => {
    let incomesTotal = 0;
    let expensesTotal = 0;
    let max = 0;

    const data = allDates.map((date) => {
      const dateData = allTxns.filter((txn) => getChartDate(txn.date) === date);
      if (dateData.length === 0) {
        return {
          date: date,
        };
      }

      dateData.forEach((element) => {
        if (element.type === "income" || element.type === "opening") {
          incomesTotal += element.amount;
          if (!isFuture(element.date)) {
            max += element.amount;
          }
        }
        if (element.type === "expense" || element.type === "loan") {
          expensesTotal += element.amount;
        }
      });

      return {
        date: date,
        earning: incomesTotal,
        spending: expensesTotal,
      };
    });

    return {
      data,
      max,
    };
  }, [allDates, allTxns]);

  return (
    <Box
      sx={{
        width: "100%",
        height: { md: "300px", xs: "200px" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        border: `1px solid rgba(255, 255, 255, 0.12)`,
        borderTop: "none",
        borderBottom: "none",
        mb: { xs: 3, sm: 4, md: 0 },
      }}
    >
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Transactions
        </Typography>
      </Toolbar>
      <ResponsiveContainer width="95%" height={200}>
        <LineChart
          width={300}
          height={200}
          data={chartData.data}
          margin={{ top: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" axisLine={true} interval="preserveStartEnd" />
          <YAxis tickFormatter={(d) => dataFormater(d)} />
          <Tooltip
            content={<CustomTooltip />}
            viewBox={{ x: 0, y: 0, width: 400, height: 400 }}
            allowEscapeViewBox={{ x: true, y: true }}
            wrapperStyle={{ zIndex: 2000 }}
          />

          <Line
            type="monotone"
            dataKey="earning"
            connectNulls
            stroke="#167412"
            strokeWidth={3}
            dot={{ r: 4 }}
            activeDot={{ r: 6 }}
          />
          <Line
            type="monotone"
            dataKey="spending"
            connectNulls
            stroke="#c76210"
            strokeWidth={3}
            dot={{ r: 4 }}
            activeDot={{ r: 6 }}
          />
          <ReferenceLine
            y={chartData.max}
            label={{
              value: "Max Budget",
              position: "insideLeft",
              stroke: "#dddddd",
              strokeWidth: 0.5,
            }}
            stroke="#a50808"
            strokeWidth={3}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default TransactionChart;

const CustomTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    const tooltipData = payload[0].payload;
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {tooltipData?.date}
          </Typography>
          <Typography variant="h6" component="div">
            {`Earnings: ${tooltipData?.earning}`}
          </Typography>
          <Typography variant="h6" component="div">
            {`Spendings: ${tooltipData?.spending}`}
          </Typography>
        </CardContent>
      </Card>
    );
  }
  return null;
};
