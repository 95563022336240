import { useMemo } from "react";

import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import { useHome } from "../../store/home-context";

import { getBankLogo } from "../../helpers/utils";
import AtmCard from "./AtmCard";
import UserCard from "./UserCard";

const ProfileCard = () => {
  const { banksList, favBank, selectedBank, activeBanks, onChangeBank } =
    useHome();

  const defaultBank = useMemo(
    () => banksList.find((bnk) => bnk.id === selectedBank),
    [banksList, selectedBank]
  );

  const bankAvatars = useMemo(() => {
    const bnkAvtr: JSX.Element[] = [];
    for (let bnk in activeBanks) {
      if (activeBanks[bnk]) {
        const bnkItem = banksList.find((item) => item.id === bnk);
        if (bnkItem) {
          const avatarPath = getBankLogo(bnkItem.bankLogo);
          bnkAvtr.push(
            <IconButton
              key={`avtr=${bnkItem.id}`}
              aria-label="selectbank"
              size="small"
              sx={{ p: 0 }}
              onClick={onChangeBank.bind(null, bnkItem.id)}
            >
              <Avatar
                alt={bnkItem.bankName}
                src={avatarPath}
                sx={{ width: 30, height: 30 }}
              />
            </IconButton>
          );
        }
      }
    }
    return bnkAvtr;
  }, [activeBanks, banksList, onChangeBank]);

  return (
    <Grid
      item
      xs
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        alignItems: "center",
      }}
    >
      <UserCard />
      <Card sx={{ width: "100%" }}>
        <CardContent sx={{ textAlign: "center", pb: 1 }}>
          {defaultBank && (
            <AtmCard
              item={defaultBank}
              isActive={true}
              isFav={defaultBank.id === favBank}
            />
          )}
        </CardContent>
        <CardActions sx={{ flexDirection: "column", mb: 1 }}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {bankAvatars}
          </Stack>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default ProfileCard;
