import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

interface Props {
  fieldType: string;
  fieldName: string;
  fieldLabel: string;
  fieldValue: string | number;
  error: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  // ref: React.Ref<HTMLInputElement>;
}

const AmountInput: React.FC<Props> = ({
  fieldType,
  fieldName,
  fieldLabel,
  fieldValue,
  error,
  onBlur,
  onChange,
}) => {
  return (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <InputLabel htmlFor={`outlined-adornment-${{ fieldName }}`}>
        {fieldLabel}
      </InputLabel>
      <OutlinedInput
        id={`outlined-adornment-${{ fieldName }}`}
        startAdornment={
          <InputAdornment position="start">
            <CurrencyRupeeIcon />
          </InputAdornment>
        }
        color="secondary"
        type="number"
        required
        label={fieldLabel}
        name={fieldName}
        value={fieldValue}
        autoComplete={fieldName}
        onChange={onChange}
        onBlur={onBlur}
        error={!!error}
      />
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

export default AmountInput;
