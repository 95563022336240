import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
  onAccept: () => void;
  onClose: () => void;
}

const Confirm: React.FC<Props> = ({ onAccept, onClose }) => (
  <Dialog
    sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
    maxWidth="xs"
    open={true}
    keepMounted
  >
    <DialogTitle>Delete</DialogTitle>
    <DialogContent dividers>Are you sure to delete this item?</DialogContent>
    <DialogActions>
      <Button
        variant="outlined"
        color="inherit"
        onClick={onClose}
        startIcon={<CloseIcon />}
      >
        Cancel
      </Button>
      <Button
        variant="outlined"
        color="error"
        onClick={onAccept}
        startIcon={<DeleteIcon />}
      >
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

export default Confirm;
