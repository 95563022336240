import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import moment from "moment";
import { getUserRef } from "../config/firebase";
import { getDbDateKey, getLoanEndDate, getStartEndTime } from "../helpers/date";
import { FormValues } from "../helpers/types";
import { filterLoans, openingInit } from "../helpers/utils";
import { AllTxns, Loan, Opening, Txn } from "../models";
import { useAuth } from "./auth-context";
import { useHome } from "./home-context";

export interface TxnsProviderProps {
  children?: ReactNode;
}

type IdType = string | undefined;

export interface TxnsContextModel {
  allTxns: AllTxns[];
  txns: Txn[];
  loans: Loan[];
  opening: Opening[];
  isLoading: boolean;
  successMsg: string | null;
  modifyTxn: (
    data: FormValues,
    txnId: IdType,
    txnType: string
  ) => Promise<void>;
  modifyOpening: (data: FormValues, openId: IdType) => Promise<void>;
  modifyLoan: (data: FormValues, loanId: IdType) => Promise<void>;
  removeTxn: (txnId: IdType, txnType: string) => Promise<void>;
  removeLoan: (loanId: IdType) => Promise<void>;
  onCloseAlert: () => void;
}

const TXNS_KEY = "txn";
const OPENING_KEY = "opening";
const LOANS_KEY = "loans";

export const TxnsContext = createContext<TxnsContextModel>(
  {} as TxnsContextModel
);

const TxnsContextProvider = ({ children }: TxnsProviderProps): JSX.Element => {
  const { selectedDate, selectedBank } = useHome();
  const { userId } = useAuth();

  const [txns, setTxns] = useState<Txn[]>([]);
  const [opening, setOpening] = useState<Opening[]>([]);
  const [loans, setLoans] = useState<Loan[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState<string | null>(null);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (successMsg) {
      timer = setTimeout(() => {
        setSuccessMsg(null);
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [successMsg]);

  useEffect(() => {
    async function getDateDatas() {
      const dbTxn: Txn[] = [];
      const userRef = getUserRef(userId);
      const txnRef = collection(userRef, TXNS_KEY);
      const q = query(
        txnRef,
        where("bank", "==", selectedBank),
        where("key", "==", getDbDateKey(selectedDate)),
        orderBy("date", "desc")
      );
      const txnQuerySnapshot = await getDocs(q);
      txnQuerySnapshot.forEach((doc) => {
        const itemData = doc.data();
        dbTxn.push(
          new Txn(
            doc.id,
            itemData.name,
            +itemData.amount,
            itemData.date,
            itemData.type,
            getDbDateKey(selectedDate),
            itemData.bank
          )
        );
      });

      const startEndTime = getStartEndTime(selectedDate);
      const dbLoans: Loan[] = [];
      const lnRef = collection(userRef, LOANS_KEY);
      const lnQuery = query(
        lnRef,
        where("bank", "==", selectedBank),
        where("end_date", ">=", startEndTime.start),
        orderBy("end_date", "desc")
      );
      const lnQuerySnapshot = await getDocs(lnQuery);
      lnQuerySnapshot.forEach((doc) => {
        const itemData = doc.data();
        dbLoans.push(
          new Loan(
            doc.id,
            itemData.name,
            +itemData.amount,
            itemData.start_date,
            itemData.end_date,
            itemData.period,
            itemData.bank,
            selectedDate
          )
        );
      });

      let dbOpening: Opening[] = [];
      const openingRef = collection(userRef, OPENING_KEY);
      const openingQuery = query(
        openingRef,
        where("bank", "==", selectedBank),
        where("key", "==", getDbDateKey(selectedDate))
      );

      const openingQuerySnapshot = await getDocs(openingQuery);
      openingQuerySnapshot.forEach((doc) => {
        const itemData = doc.data();
        dbOpening.push(
          new Opening(doc.id, +itemData.amount, selectedDate, itemData.bank)
        );
      });

      if (dbOpening.length === 0) {
        dbOpening.push(openingInit(selectedDate, selectedBank, 0));
      }

      setOpening(dbOpening);
      setTxns(dbTxn);
      setLoans(filterLoans(dbLoans, selectedDate));
      setIsLoading(false);
    }

    if (selectedBank && userId) {
      setIsLoading(true);
      getDateDatas();
    }
  }, [selectedBank, selectedDate, userId]);

  const allTxns = useMemo(() => {
    const sortedList = [...txns, ...loans].sort((left, right) =>
      moment.utc(left.date).diff(moment.utc(right.date))
    );

    let balance = 0;
    const allTxnsData: AllTxns[] = [];
    if (opening.length === 1) {
      const openItem = opening[0];
      balance += openItem.amount;

      allTxnsData.push(
        new AllTxns(
          openItem.id,
          openItem.name,
          openItem.amount,
          openItem.date,
          openItem.type,
          openItem.key,
          openItem.bank,
          balance
        )
      );
    }

    sortedList.forEach((item) => {
      if (item.type === "expense" || item.type === "loan") {
        balance -= item.amount;
      } else {
        balance += item.amount;
      }

      allTxnsData.push(
        new AllTxns(
          item.id,
          item.name,
          item.amount,
          item.date,
          item.type,
          item.key,
          item.bank,
          balance,
          item?.remaining
        )
      );
    });
    return allTxnsData;
  }, [txns, loans, opening]);

  const modifyTxnHandler = useCallback(
    async (data: FormValues, txnId: IdType, txnType: string) => {
      const userRef = getUserRef(userId);
      const txnDbKey = getDbDateKey(data.txnDate);
      const txnItem = {
        name: data.txnName,
        amount: data.txnAmount,
        date: data.txnDate,
        type: txnType,
        key: txnDbKey,
        bank: data.txnBank,
      };

      if (!txnId) {
        try {
          const addTxnRef = await addDoc(
            collection(userRef, TXNS_KEY),
            txnItem
          );
          setTxns((curTxns) => {
            const newTxns = [...curTxns];
            newTxns.push(
              new Txn(
                addTxnRef.id,
                data.txnName,
                +data.txnAmount,
                data.txnDate,
                txnType,
                txnDbKey,
                data.txnBank
              )
            );
            return newTxns;
          });
          setSuccessMsg(
            `${txnType === "income" ? "Income" : "Expense"} ${
              !txnId ? "Added" : "Updated"
            } Successfully!`
          );
        } catch (error) {}
      } else {
        try {
          const editTxnRef = doc(userRef, TXNS_KEY, txnId);
          await updateDoc(editTxnRef, txnItem);
          setTxns((curTxns) => {
            const newTxns = [...curTxns];
            const index = newTxns.findIndex((tx) => tx.id === txnId);
            newTxns[index] = new Txn(
              txnId,
              data.txnName,
              +data.txnAmount,
              data.txnDate,
              txnType,
              txnDbKey,
              data.txnBank
            );
            return newTxns;
          });
          setSuccessMsg(
            `${txnType === "income" ? "Income" : "Expense"} ${
              !txnId ? "Added" : "Updated"
            } Successfully!`
          );
        } catch (error) {}
      }
    },
    [userId]
  );

  const modifyOpeningHandler = useCallback(
    async (data: FormValues, openId: IdType) => {
      const userRef = getUserRef(userId);
      const txnDbKey = getDbDateKey(selectedDate);
      const openItem = {
        amount: data.txnAmount,
        key: txnDbKey,
        bank: data.txnBank,
      };

      if (!openId || openId === "new") {
        try {
          const addOpenRef = await addDoc(
            collection(userRef, OPENING_KEY),
            openItem
          );
          setOpening([
            new Opening(
              addOpenRef.id,
              +openItem.amount,
              selectedDate,
              openItem.bank
            ),
          ]);
          setSuccessMsg("Opening Balance Added Successfully!");
        } catch (error) {}
      } else {
        try {
          const editOpenRef = doc(userRef, OPENING_KEY, openId);
          await updateDoc(editOpenRef, openItem);
          setOpening([
            new Opening(openId, +openItem.amount, selectedDate, openItem.bank),
          ]);
          setSuccessMsg("Opening Balance Updated Successfully!");
        } catch (error) {}
      }
    },
    [selectedDate, userId]
  );

  const modifyLoanHandler = useCallback(
    async (data: FormValues, loanId: IdType) => {
      const endDate = getLoanEndDate(data.txnDate, data.txnPeriod);
      const userRef = getUserRef(userId);
      const lnItem = {
        name: data.txnName,
        amount: data.txnAmount,
        start_date: data.txnDate,
        end_date: endDate,
        period: data.txnPeriod,
        bank: data.txnBank,
      };
      if (!loanId) {
        try {
          const addLnRef = await addDoc(collection(userRef, LOANS_KEY), lnItem);
          setLoans((curLoans) => {
            const newLoans = [...curLoans];
            newLoans.push(
              new Loan(
                addLnRef.id,
                data.txnName,
                +data.txnAmount,
                data.txnDate,
                endDate,
                data.txnPeriod,
                data.txnBank,
                selectedDate
              )
            );
            return filterLoans(newLoans, selectedDate);
          });
          setSuccessMsg("Loan Added Successfully!");
        } catch (error) {}
      } else {
        try {
          const editLnRef = doc(userRef, LOANS_KEY, loanId);
          await updateDoc(editLnRef, lnItem);
          setLoans((curLoans) => {
            const newLoans = [...curLoans];
            const index = newLoans.findIndex((ln) => ln.id === loanId);
            newLoans[index] = new Loan(
              loanId,
              data.txnName,
              +data.txnAmount,
              data.txnDate,
              endDate,
              data.txnPeriod,
              data.txnBank,
              selectedDate
            );
            return filterLoans(newLoans, selectedDate);
          });
          setSuccessMsg("Loan Updated Successfully!");
        } catch (error) {}
      }
    },
    [selectedDate, userId]
  );

  const removeTxnHandler = useCallback(
    async (txnId: IdType, txnType: string) => {
      if (txnId) {
        setIsLoading(false);
        try {
          const userRef = getUserRef(userId);
          await deleteDoc(doc(userRef, TXNS_KEY, txnId));
          setTxns((curTxns) => curTxns.filter((tx) => tx.id !== txnId));
          setSuccessMsg(
            `${
              txnType === "income" ? "Income" : "Expense"
            } Deleted Successfully!`
          );
          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
          // setIsError(err.message || "Something went wrong!");
        }
      }
    },
    [userId]
  );

  const removeLoanHandler = useCallback(
    async (loanId: IdType) => {
      if (loanId) {
        setIsLoading(false);
        try {
          const userRef = getUserRef(userId);
          await deleteDoc(doc(userRef, LOANS_KEY, loanId));
          setLoans((curLoans) => curLoans.filter((ln) => ln.id !== loanId));
          setSuccessMsg(`Loan Deleted Successfully!`);
          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
          // setIsError(err.message || "Something went wrong!");
        }
      }
    },
    [userId]
  );

  const handleAlertClose = useCallback(() => setSuccessMsg(null), []);

  const values: TxnsContextModel = useMemo(
    () => ({
      allTxns,
      txns,
      loans,
      opening,
      isLoading,
      successMsg,
      modifyTxn: modifyTxnHandler,
      modifyOpening: modifyOpeningHandler,
      modifyLoan: modifyLoanHandler,
      removeTxn: removeTxnHandler,
      removeLoan: removeLoanHandler,
      onCloseAlert: handleAlertClose,
    }),
    [
      isLoading,
      txns,
      loans,
      opening,
      allTxns,
      successMsg,
      modifyTxnHandler,
      modifyOpeningHandler,
      modifyLoanHandler,
      removeTxnHandler,
      removeLoanHandler,
      handleAlertClose,
    ]
  );

  return <TxnsContext.Provider value={values}>{children}</TxnsContext.Provider>;
};

export default TxnsContextProvider;

export function useTxns(): TxnsContextModel {
  return useContext(TxnsContext);
}
