import { useCallback, useEffect, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import SaveIcon from "@mui/icons-material/Save";

import { ActiveBanks } from "../../helpers/types";
import { useHome } from "../../store/home-context";
import AtmCard from "../Cards/AtmCard";
import SuccessAlert from "../UI/SuccessAlert";

function SettingsSection() {
  const {
    banksList,
    activeBanks,
    favBank,
    successMsg,
    onSaveBankSettings,
    onCloseAlert,
  } = useHome();

  const [enabledBanks, setEnabledBanks] = useState<ActiveBanks>({});
  const [defaultBank, setDefaultBank] = useState<string>("");
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    setEnabledBanks(activeBanks);
  }, [activeBanks]);

  useEffect(() => {
    setDefaultBank(favBank);
  }, [favBank]);

  const selectBankHandler = useCallback((bank: string) => {
    setEnabledBanks((curBanks) => {
      return {
        ...curBanks,
        [bank]: !curBanks[bank],
      };
    });
    setIsChanged(true);
  }, []);

  const selectFavBankHandler = useCallback((bank: string) => {
    setDefaultBank(bank);
    setIsChanged(true);
  }, []);

  const saveBankSettingsHandler = useCallback(() => {
    onSaveBankSettings(enabledBanks, defaultBank);
    setIsChanged(false);
  }, [defaultBank, enabledBanks, onSaveBankSettings]);

  const banksBlock = useMemo(() => {
    return banksList.map((bankItem) => {
      const bankIsActive = enabledBanks[bankItem.id]
        ? enabledBanks[bankItem.id]
        : false;
      const bankIsFav = bankItem.id === defaultBank;

      return (
        <Grid item xs={12} md={6} lg={4} key={bankItem.id}>
          <Paper variant="outlined" sx={{ p: 1 }}>
            <AtmCard
              item={bankItem}
              isActive={bankIsActive}
              isFav={bankIsFav}
              onEnableBank={selectBankHandler}
              onFavBank={selectFavBankHandler}
            />
          </Paper>
        </Grid>
      );
    });
  }, [
    banksList,
    defaultBank,
    enabledBanks,
    selectBankHandler,
    selectFavBankHandler,
  ]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="start"
        spacing={1}
        sx={{ border: `1px solid rgba(255, 255, 255, 0.12)`, p: 1 }}
      >
        <Grid item xs={12} sx={{ textAlign: "right" }}>
          <Button
            variant="outlined"
            size="large"
            color="secondary"
            disabled={!isChanged}
            startIcon={<SaveIcon />}
            onClick={saveBankSettingsHandler}
          >
            Save
          </Button>
        </Grid>
        {banksBlock}
      </Grid>
      {!!successMsg && (
        <SuccessAlert message={successMsg} onClose={onCloseAlert} />
      )}
    </Box>
  );
}

export default SettingsSection;
