import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import React from "react";
import { CreditLoan } from "../../models";
import CreditLoanItem from "./CreditLoanItem";

interface Props {
  items: CreditLoan[];
  onSelect: (item: CreditLoan) => void;
}

const CreditCardLoans: React.FC<Props> = ({ items, onSelect }) => {
  return (
    <>
      {items.length === 0 && (
        <ListItem>
          <ListItemText
            primary={
              <Typography
                variant="h6"
                component="div"
                role="balance"
                sx={{ textAlign: "center" }}
              >
                No Data
              </Typography>
            }
          />
        </ListItem>
      )}
      {items.length > 0 &&
        items.map((item: CreditLoan) => (
          <CreditLoanItem
            key={`${item.type}-${item.id}`}
            item={item}
            onSelectItem={onSelect.bind(null, item)}
          />
        ))}
    </>
  );
};

export default CreditCardLoans;
