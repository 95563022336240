class Bank {
  id: string;
  bankName: string;
  bankLogo: string;
  longName: string;

  constructor(
    id: string,
    bankName: string,
    bankLogo: string,
    longName: string
  ) {
    this.id = id;
    this.bankName = bankName;
    this.bankLogo = bankLogo;
    this.longName = longName;
  }
}

export default Bank;
