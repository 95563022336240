import { Moment } from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import DialogContent from "@mui/material/DialogContent";

import AmountInput from "../UI/Fields/AmountInput";
import BankSelect from "../UI/Fields/BankSelect";
import DateInput from "../UI/Fields/DateInput";
import NameInput from "../UI/Fields/NameInput";

import { useHome } from "../../store/home-context";
import { useTxns } from "../../store/txns-context";

import { getDateWithTime, getModalDate } from "../../helpers/date";
import { EditType, FormValues } from "../../helpers/types";

import {
  validateAmount,
  validateName,
  validatePeriod,
} from "../../helpers/utils";
import { Loan, Option } from "../../models";
import Confirm from "../UI/Confirm";
import FormActions from "../UI/FormActions";
import FormHeader from "../UI/FormHeader";
import FormModal from "../UI/FormModal";

type Props = {
  tab: number;
  editItem: EditType;
  onClose: () => void;
};

const TransactionForm: React.FC<Props> = ({ tab, editItem, onClose }) => {
  const isLoan = useMemo(
    () => tab === 3 || (editItem && editItem.type === "loan"),
    [tab, editItem]
  );

  const isOpening = useMemo(
    () => (tab === 0 || tab === 1) && editItem && editItem.type === "opening",
    [tab, editItem]
  );

  const { activeBanks, banksList, selectedBank, selectedDate } = useHome();
  const { modifyLoan, modifyTxn, modifyOpening, removeLoan, removeTxn } =
    useTxns();

  const {
    control,
    formState: { isSubmitting, isDirty, isValid },
    handleSubmit,
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      txnName: "",
      txnAmount: 0,
      txnDate: getModalDate(selectedDate),
      txnBank: selectedBank,
      txnPeriod: 1,
    },
    mode: "onChange",
  });

  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  useEffect(() => {
    if (!editItem) {
      setValue("txnBank", selectedBank);
    }
  }, [selectedBank, editItem, setValue]);

  useEffect(() => {
    if (!editItem) {
      setValue("txnDate", getModalDate(selectedDate));
    }
  }, [selectedDate, editItem, setValue]);

  useEffect(() => {
    if (editItem) {
      setValue("txnName", editItem.name);
      setValue("txnAmount", editItem.amount);
      setValue("txnBank", editItem.bank);
      if (editItem.type === "loan") {
        const loanItem = editItem as Loan;
        setValue("txnDate", getDateWithTime(loanItem.start_date));
        setValue("txnPeriod", loanItem.period);
      } else {
        setValue("txnDate", getDateWithTime(editItem.date));
      }
    }
  }, [editItem, setValue]);

  const formTitle = useMemo(() => {
    if (editItem) {
      switch (editItem?.type) {
        case "income":
          return "Modify Income";
        case "expense":
          return "Modify Expense";
        case "loan":
          return "Modify Loan";
        default:
          break;
      }
    }

    switch (tab) {
      case 1:
        return "Add Income";
      case 2:
        return "Add Expense";
      case 3:
        return "Add Loan";
      default:
        return "";
    }
  }, [editItem, tab]);

  const dateChangeHandler = useCallback(
    (value: Moment | null | undefined) => {
      setValue("txnDate", getDateWithTime(value), { shouldDirty: true });
    },
    [setValue]
  );

  const bankOptions = useMemo(() => {
    const options: Option[] = [];
    banksList.forEach((bank) => {
      if (activeBanks[bank.id] && bank.id === selectedBank) {
        options.push(new Option(bank.bankName, bank.id));
      }
    });
    return options;
  }, [activeBanks, banksList, selectedBank]);

  const deleteTxnHandler = useCallback(() => {
    setShowConfirm(true);
  }, []);

  const confirmDeleteHandler = useCallback(async () => {
    if (editItem) {
      if (editItem.type === "loan") {
        await removeLoan(editItem.id);
      } else {
        await removeTxn(editItem.id, editItem.type);
      }
    }
    setShowConfirm(false);
    onClose();
  }, [editItem, onClose, removeLoan, removeTxn]);

  const closeConfirmHandler = useCallback(() => {
    setShowConfirm(false);
  }, []);

  const onSubmit = useCallback(
    async (data: FormValues) => {
      if (!isValid) {
        return false;
      }

      if (editItem?.type === "opening") {
        await modifyOpening(data, editItem?.id);
      } else if (tab === 3 || editItem?.type === "loan") {
        await modifyLoan(data, editItem?.id);
      } else if (
        tab === 1 ||
        tab === 2 ||
        editItem?.type === "income" ||
        editItem?.type === "expense"
      ) {
        await modifyTxn(
          data,
          editItem?.id,
          editItem?.type ? editItem?.type : tab === 2 ? "expense" : "income"
        );
      }
      onClose();
    },
    [tab, editItem, isValid, modifyTxn, modifyOpening, modifyLoan, onClose]
  );

  return (
    <FormModal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormHeader title={formTitle} onClose={onClose} />
        <DialogContent dividers>
          {!isOpening && (
            <Controller
              control={control}
              name="txnName"
              rules={{
                required: "Name is required!",
                validate: validateName,
              }}
              render={({
                field: { name, onChange, onBlur, value },
                fieldState: { error },
              }) => (
                <NameInput
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldValue={value}
                  fieldName={name}
                  fieldLabel="Name"
                  error={error?.message}
                  fieldType="text"
                />
              )}
            />
          )}
          <Controller
            control={control}
            name="txnAmount"
            rules={{
              required: "Amount is required!",
              validate: validateAmount,
            }}
            render={({
              field: { name, onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <AmountInput
                onChange={onChange}
                onBlur={onBlur}
                fieldValue={value}
                fieldName={name}
                fieldLabel={`${isLoan ? "EMI " : ""}Amount`}
                error={error?.message}
                fieldType="number"
              />
            )}
          />
          {!isOpening && (
            <Controller
              control={control}
              name="txnDate"
              rules={{
                required: "Date is required!",
              }}
              render={({
                field: { name, onBlur, value },
                fieldState: { error },
              }) => (
                <DateInput
                  onChange={(value: Moment | null | undefined) =>
                    dateChangeHandler(value)
                  }
                  onBlur={onBlur}
                  fieldValue={value}
                  fieldName={name}
                  fieldLabel={`${isLoan ? "First EMI " : ""}Date`}
                  error={error?.message}
                  hasMinMax={!isLoan}
                  fieldType="text"
                />
              )}
            />
          )}
          <Controller
            control={control}
            name="txnBank"
            rules={{
              required: "Bank is required.",
            }}
            render={({
              field: { name, onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <BankSelect
                onChange={onChange}
                onBlur={onBlur}
                fieldValue={value}
                fieldName={name}
                fieldOptions={bankOptions}
                fieldLabel="Bank"
                error={error?.message}
                fieldType="text"
              />
            )}
          />
          {isLoan && (
            <Controller
              control={control}
              name="txnPeriod"
              rules={{
                required: "Total EMI is required.",
                validate: validatePeriod,
              }}
              render={({
                field: { name, onChange, onBlur, value },
                fieldState: { error },
              }) => (
                <NameInput
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldValue={value}
                  fieldName={name}
                  fieldLabel="Total EMI"
                  error={error?.message}
                  fieldType="number"
                />
              )}
            />
          )}
        </DialogContent>
        <FormActions
          showDelete={!!editItem}
          isWaiting={isSubmitting}
          disabled={!isDirty || !isValid}
          onClose={onClose}
          onDelete={deleteTxnHandler}
        />
      </form>
      {showConfirm && (
        <Confirm
          onAccept={confirmDeleteHandler}
          onClose={closeConfirmHandler}
        />
      )}
    </FormModal>
  );
};

export default TransactionForm;
