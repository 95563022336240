import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";

import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

import { useAuth } from "../../store/auth-context";
import Dates from "../UI/Dates";

import logoPath from "../../assets/favicon_white.png";

function Header() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (e) {
      // console.log(e);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        border: `1px solid rgba(255, 255, 255, 0.12)`,
        borderLeft: "none",
      }}
    >
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Avatar alt="Anish" src={logoPath} />
          <Box sx={{ flexGrow: 1 }}>
            <Dates />
          </Box>
          <Tooltip title="Logout">
            <IconButton sx={{ p: 0 }} onClick={handleLogout}>
              <PowerSettingsNewIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
}

export default Header;
