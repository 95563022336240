import moment from "moment";
import { Dates } from "../helpers/types";
import { getDbDateKey } from "../helpers/date";

class Opening {
  id: string;
  name: string;
  amount: number;
  date: Dates;
  bank: string;
  type: string;
  key: string;

  constructor(id: string, amount: number, date: Dates, bank: string) {
    this.id = id;
    this.name = "Opening Balance";
    this.amount = amount;
    this.date = moment(date).startOf("month");
    this.bank = bank;
    this.type = "opening";
    this.key = getDbDateKey(date);
  }
}

export default Opening;
