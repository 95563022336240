import { Navigate } from "react-router-dom";
import { useAuth } from "../store/auth-context";
import LoadingPage from "../components/UI/LoadingPage";

const NonProtectedRoute: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (user) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default NonProtectedRoute;
