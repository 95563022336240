import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";

import { Bank } from "../../models";

interface Props {
  item: Bank;
  isActive: boolean;
  onClick: () => void;
}

const ButtonChip: React.FC<Props> = ({ item, isActive, onClick }) => {
  return (
    <Button color="secondary" onClick={onClick}>
      <Chip
        avatar={
          <Avatar
            src={require(`../../assets/bank/${item.bankLogo}`)}
            alt={item.id}
          />
        }
        label={item.longName}
        variant={isActive ? "filled" : "outlined"}
        color={isActive ? "success" : "default"}
        sx={{ cursor: "pointer" }}
      />
    </Button>
  );
};

export default ButtonChip;
