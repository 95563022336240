import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useAuth } from "../../store/auth-context";

const ProfileCard = () => {
  const { user } = useAuth();

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "#277f8f",
      }}
    >
      <Card>
        <CardMedia
          component="img"
          alt="green iguana"
          height="200"
          image="https://fastly.picsum.photos/id/89/4608/2592.jpg?hmac=G9E4z5RMJgMUjgTzeR4CFlORjvogsGtqFQozIRqugBk"
        />
        <Avatar
          alt={user?.displayName ? user.displayName : "Profile Image"}
          variant="rounded"
          src={
            "https://fastly.picsum.photos/id/8/5000/3333.jpg?hmac=OeG5ufhPYQBd6Rx1TAldAuF92lhCzAhKQKttGfawWuA"
          }
          sx={{ width: 150, height: 150, m: "auto", mt: -10 }}
        />
        <CardContent sx={{ textAlign: "center", borderRadius: 10 }}>
          <Typography gutterBottom variant="h5" component="div">
            {user?.displayName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Software Engineer
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ProfileCard;
