import { useCallback, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";

import AccountCircle from "@mui/icons-material/AccountCircle";
// import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import SaveIcon from "@mui/icons-material/Save";
import { red } from "@mui/material/colors";

import { validateUserName } from "../../helpers/utils";
import { useAuth } from "../../store/auth-context";
import SuccessAlert from "../UI/SuccessAlert";

interface IFormInputs {
  userName: string;
}

const ProfileForm = () => {
  const { successMsg, onChangeProfile, onCloseAlert } = useAuth();

  const [formError, setFormError] = useState<string | null>(null);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm<IFormInputs>({
    defaultValues: {
      userName: "",
    },
    mode: "onChange",
  });

  const closeAlertHandler = useCallback(() => setFormError(null), []);

  const onSubmit: SubmitHandler<IFormInputs> = useCallback(
    (data) => {
      setFormError(null);
      const { userName } = data;

      if (!isValid) {
        return false;
      }

      try {
        onChangeProfile(userName);
        setFormError(null);
        reset({
          userName: "",
        });
      } catch (error) {
        setFormError("Profile update failed");
        // console.log("submit error", error);
      }
    },
    [isValid, onChangeProfile, reset]
  );

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 2,
      }}
    >
      <Card sx={{ width: "100%" }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              R
            </Avatar>
          }
          title="Account Settings"
        />
        <CardContent
          sx={{
            "&:last-child": {
              pb: 1,
            },
          }}
        >
          <Controller
            name="userName"
            control={control}
            rules={{
              required: "Name is required!",
              validate: validateUserName,
            }}
            render={({
              field: { name, value, onChange, onBlur, ref },
              fieldState: { error },
            }) => (
              <FormControl
                fullWidth
                sx={{ mb: 2 }}
                error={!!error}
                color="secondary"
              >
                <InputLabel htmlFor="name" color="secondary">
                  Name
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-name"
                  startAdornment={
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  }
                  label="Name"
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  inputRef={ref}
                  inputProps={{ maxLength: 32 }}
                />
                <FormHelperText id="password-error-text">
                  {error?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
          {/* <FormControl fullWidth>
            <InputLabel htmlFor="outlined-adornment-email" color="secondary">
              Email
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-email"
              startAdornment={
                <InputAdornment position="start">
                  <AlternateEmailIcon />
                </InputAdornment>
              }
              label="Email"
              fullWidth
              defaultValue=""
              color="secondary"
              disabled
            />
          </FormControl> */}
        </CardContent>
        <CardActions disableSpacing sx={{ justifyContent: "center" }}>
          <Stack spacing={2} direction="row">
            {/* <Button variant="text" size="large" color="inherit" fullWidth>
              Cancel
            </Button> */}
            <Button
              type="submit"
              variant="outlined"
              size="large"
              color="secondary"
              disabled={isSubmitting || !isDirty || !isValid}
              fullWidth
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Stack>
        </CardActions>
      </Card>
      {!!formError && (
        <SuccessAlert
          message={formError}
          severity="error"
          onClose={closeAlertHandler}
        />
      )}
      {!!successMsg && (
        <SuccessAlert message={successMsg} onClose={onCloseAlert} />
      )}
    </Box>
  );
};

export default ProfileForm;
