import Box from "@mui/material/Box";

import CreditCardSection from "../CreditCard/CreditCardSection";
import ProfileCard from "./ProfileCard";

function CardsSection() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { xs: "100%", md: "30%", lg: "25%" },
      }}
    >
      <ProfileCard />
      <CreditCardSection />
    </Box>
  );
}

export default CardsSection;
