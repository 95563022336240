import { useHome } from "../store/home-context";
import CardsSection from "./Cards/CardsSection";
import SummarySection from "./Summary/SummarySection";
import TransactionSection from "./Transactions/TransactionSection";
import LoadingPage from "./UI/LoadingPage";
import NoBankAlert from "./UI/NoBankAlert";

const Dashboard = () => {
  const { isLoading, selectedBank } = useHome();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!selectedBank) {
    return <NoBankAlert />;
  }

  return (
    <>
      {/* Summary */}
      <SummarySection />
      {/* Transactions */}
      <TransactionSection />
      {/* Profile */}
      <CardsSection />
    </>
  );
};

export default Dashboard;
