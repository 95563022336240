import TextField from "@mui/material/TextField";

interface Props {
  fieldType: string;
  fieldName: string;
  fieldLabel: string;
  fieldValue: string | number;
  error: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  // ref: React.Ref<HTMLInputElement>;
}

const NameInput: React.FC<Props> = ({
  fieldType,
  fieldName,
  fieldLabel,
  fieldValue,
  error,
  onBlur,
  onChange,
}) => {
  return (
    <TextField
      type={fieldType}
      label={fieldLabel}
      autoComplete={fieldName}
      margin="normal"
      // required
      fullWidth
      name={fieldName}
      value={fieldValue}
      onChange={onChange}
      onBlur={onBlur}
      // inputRef={ref}
      error={!!error}
      helperText={error}
      color="secondary"
      inputProps={{ maxLength: 32 }}
      sx={{ mt: 0, mb: 2 }}
    />
  );
};
export default NameInput;
