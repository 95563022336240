import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

const darkState = true;
// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: darkState ? "dark" : "light",
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
