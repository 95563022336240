import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { /*useLocation,*/ useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";

import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { validateEmail, validatePassword } from "../../helpers/utils";
import { useAuth } from "../../store/auth-context";
import PasswordInput from "../UI/Fields/PasswordInput";

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText("#0d4f6d"),
  borderColor: "#0d4f6d",
  paddingTop: "8px",
  paddingBottom: "8px",
  "&:hover": {
    opacity: 0.75,
    borderColor: "#0d4f6d",
  },
}));

interface IFormInputs {
  email: string;
  password: string;
}

const Login = () => {
  let navigate = useNavigate();
  // let location = useLocation();
  const { signIn } = useAuth();

  // let from = location.state?.from?.pathname || "/";

  const [formError, setFormError] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<IFormInputs>({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onChange",
  });

  const onSubmit = useCallback(
    async (data: IFormInputs) => {
      if (!isValid) {
        return false;
      }

      setFormError(null);
      try {
        await signIn(data.email, data.password);
        navigate("/", { replace: true });
      } catch (error: any) {
        setFormError("Invalid Credentials");
      }
    },
    [isValid, navigate, signIn]
  );

  const closeAlertHandler = useCallback(() => setFormError(null), []);

  return (
    <Box
      sx={{
        position: "absolute",
        width: "fit-content",
        height: "fit-content",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px solid #0d4f6d",
        borderRadius: 4,
        p: 4,
        backgroundColor: "rgba(13, 79, 109, 0.3)",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Welcome Back!!!
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ mt: 1 }}
      >
        <Controller
          name="email"
          control={control}
          rules={{
            required: "Email is required.",
            validate: validateEmail,
          }}
          render={({
            field: { name, value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <TextField
              type="email"
              label="Email Address"
              autoComplete="email"
              margin="normal"
              color="secondary"
              required
              fullWidth
              autoFocus
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              inputRef={ref}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          rules={{
            required: "Password is required.",
            validate: validatePassword,
          }}
          render={({
            field: { name, value, onChange, onBlur, ref },
            fieldState: { error },
          }) => (
            <PasswordInput
              onChange={onChange}
              onBlur={onBlur}
              fieldValue={value}
              fieldName={name}
              fieldLabel="Password"
              error={error?.message}
            />
          )}
        />

        {formError && <Alert severity="error">{formError}</Alert>}
        <ColorButton
          type="submit"
          fullWidth
          variant="outlined"
          disabled={isSubmitting || !isValid}
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </ColorButton>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={!!formError}
        autoHideDuration={5000}
        onClose={closeAlertHandler}
        message={formError}
      >
        <Alert severity="error">{formError}</Alert>
      </Snackbar>
    </Box>
  );
};

export default Login;
