import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import { AllTxns } from "../../models";
import TransactionItem from "./TransactionItem";

interface Props {
  items: AllTxns[];
  tab: number;
  onSelectItem: (id: AllTxns) => void;
}

const TransactionList: React.FC<Props> = ({ items, tab, onSelectItem }) => {
  return (
    <List
      sx={{
        flex: "1 1 100%",
        width: "100%",
      }}
    >
      {items.length === 0 && (
        <ListItem>
          <ListItemText
            primary={
              <Typography
                variant="h6"
                component="div"
                role="balance"
                sx={{ textAlign: "center" }}
              >
                No Data
              </Typography>
            }
          />
        </ListItem>
      )}
      {items.length > 0 &&
        items.map((item: AllTxns) => (
          <TransactionItem
            key={`${item.type}-${item.id}`}
            item={item}
            tab={tab}
            onSelectItem={onSelectItem.bind(null, item)}
          />
        ))}
    </List>
  );
};

export default TransactionList;
