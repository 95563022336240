import { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import DialogContent from "@mui/material/DialogContent";

import AmountInput from "../UI/Fields/AmountInput";
import FormActions from "../UI/FormActions";
import FormHeader from "../UI/FormHeader";
import FormModal from "../UI/FormModal";

import { CreditFormValues } from "../../helpers/types";
import { validateAmount } from "../../helpers/utils";
import { useCredit } from "../../store/credit-context";

type Props = {
  openForm: boolean;
  onClose: () => void;
};

const CreditCardForm: React.FC<Props> = ({ openForm, onClose }) => {
  const { credit, modifyCredit } = useCredit();

  const {
    control,
    formState: { isSubmitting, isDirty, isValid },
    handleSubmit,
    setValue,
  } = useForm<CreditFormValues>({
    defaultValues: {
      ccBilled: 0,
      ccDebit: 0,
      ccCredit: 0,
    },
    mode: "onChange",
  });

  useEffect(() => {
    if (credit) {
      setValue("ccBilled", credit.billed);
      setValue("ccDebit", credit.debit);
      setValue("ccCredit", credit.credit);
    }
  }, [credit, setValue]);

  const onSubmit = useCallback(
    async (data: CreditFormValues) => {
      if (!isValid) {
        return false;
      }

      await modifyCredit(data);
      onClose();
    },
    [modifyCredit, isValid, onClose]
  );

  return (
    <FormModal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormHeader title="Credit Card Form" onClose={onClose} />
        <DialogContent dividers>
          <Controller
            control={control}
            name="ccBilled"
            rules={{
              required: "Amount is required!",
              validate: validateAmount,
            }}
            render={({
              field: { name, onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <AmountInput
                onChange={onChange}
                onBlur={onBlur}
                fieldValue={value}
                fieldName={name}
                fieldLabel="Billed"
                error={error?.message}
                fieldType="number"
              />
            )}
          />
          <Controller
            control={control}
            name="ccDebit"
            rules={{
              required: "Amount is required!",
              validate: validateAmount,
            }}
            render={({
              field: { name, onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <AmountInput
                onChange={onChange}
                onBlur={onBlur}
                fieldValue={value}
                fieldName={name}
                fieldLabel="Unbilled Debit"
                error={error?.message}
                fieldType="number"
              />
            )}
          />
          <Controller
            control={control}
            name="ccCredit"
            rules={{
              required: "Amount is required!",
              validate: validateAmount,
            }}
            render={({
              field: { name, onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <AmountInput
                onChange={onChange}
                onBlur={onBlur}
                fieldValue={value}
                fieldName={name}
                fieldLabel="Unbilled Credit"
                error={error?.message}
                fieldType="number"
              />
            )}
          />
        </DialogContent>
        <FormActions
          showDelete={false}
          disabled={isSubmitting || !isDirty || !isValid}
          onClose={onClose}
        />
      </form>
    </FormModal>
  );
};

export default CreditCardForm;
