import { Navigate, useLocation } from "react-router-dom";
import LoadingPage from "../components/UI/LoadingPage";
import { useAuth } from "../store/auth-context";
import CreditContextProvider from "../store/credit-context";
import HomeContextProvider from "../store/home-context";
import TxnsContextProvider from "../store/txns-context";

const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { user, isLoading } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <HomeContextProvider>
      <TxnsContextProvider>
        <CreditContextProvider>{children}</CreditContextProvider>
      </TxnsContextProvider>
    </HomeContextProvider>
  );
};

export default ProtectedRoute;
