import { Credit } from "../../models";
import CreditCardItem from "./CreditCardItem";

interface Props {
  creditItem: Credit;
  onSelectLoans: (value: boolean) => void;
  onSelectForm: () => void;
}

const CreditCardOverview: React.FC<Props> = ({
  creditItem,
  onSelectLoans,
  onSelectForm,
}) => {
  return (
    <>
      <CreditCardItem
        title="Billed"
        amount={creditItem.billed}
        onClick={onSelectForm}
      />
      <CreditCardItem
        title="Unbilled Debit"
        amount={creditItem.debit}
        onClick={onSelectForm}
      />
      <CreditCardItem
        title="Credit Loans"
        amount={creditItem.loansTotal}
        onClick={onSelectLoans.bind(null, false)}
      />
      <CreditCardItem
        title="Unbilled Credit"
        amount={creditItem.credit}
        balance={creditItem.balance}
        onClick={onSelectForm}
      />
    </>
  );
};

export default CreditCardOverview;
