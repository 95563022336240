import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import BgPatternCard from "../../assets/bg-pattern-card.svg";

import { useAuth } from "../../store/auth-context";
import ProfileLink from "../UI/ProfileLink";
import SettingsLink from "../UI/SettingsLink";

const UserCard = () => {
  const { user } = useAuth();

  return (
    <Card sx={{ width: "100%" }}>
      <CardMedia sx={{ height: 100 }} image={BgPatternCard} title="Profile" />
      <CardContent
        sx={{
          textAlign: "center",
          "&:last-child": {
            pb: 1,
          },
        }}
      >
        <Avatar
          alt={user?.displayName ? user.displayName : "Profile Image"}
          // src={avatarPath}
          sx={{ width: 70, height: 70, m: "auto", mt: -6, mb: 2 }}
        />
        <Typography gutterBottom variant="h5" component="div">
          {user?.displayName}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          Software Engineer
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={2}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <ProfileLink />
          <SettingsLink />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default UserCard;
