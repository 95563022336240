import { useCallback, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import { ActiveBanks } from "../../helpers/types";
import { useHome } from "../../store/home-context";
import ButtonChip from "./ButtonChip";

const steps = [
  {
    label: "Overview",
    description: `You have not selected a bank. Please select a bank to continue.`,
  },
  {
    label: "Select All Your Banks",
    description: "",
  },
  {
    label: "Select Your Default Bank",
    description: "",
  },
];

const NoBankAlert = () => {
  const { banksList, onSaveBankSettings } = useHome();

  const [activeStep, setActiveStep] = useState(0);
  const [enabledBanks, setEnabledBanks] = useState<ActiveBanks>({});
  const [defaultBank, setDefaultBank] = useState<string>("");

  const maxSteps = steps.length;

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const saveBankSettingsHandler = useCallback(() => {
    onSaveBankSettings(enabledBanks, defaultBank);
  }, [defaultBank, enabledBanks, onSaveBankSettings]);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const selectBankHandler = useCallback((bank: string) => {
    setEnabledBanks((curBanks) => {
      return {
        ...curBanks,
        [bank]: !curBanks[bank],
      };
    });
  }, []);

  const selectFavBankHandler = useCallback((bank: string) => {
    setDefaultBank(bank);
  }, []);

  const stepperDescription = useMemo(() => {
    if (activeStep === 0) {
      return steps[activeStep].description;
    }

    if (activeStep === 1) {
      return (
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          direction="row"
          useFlexGap
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
        >
          {banksList.map((bankItem) => {
            const bankIsActive = enabledBanks[bankItem.id]
              ? enabledBanks[bankItem.id]
              : false;

            return (
              <ButtonChip
                key={bankItem.id}
                item={bankItem}
                isActive={bankIsActive}
                onClick={selectBankHandler.bind(null, bankItem.id)}
              />
            );
          })}
        </Stack>
      );
    }

    if (activeStep === 2) {
      return (
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          direction="row"
          useFlexGap
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
        >
          {banksList.map((bankItem) => {
            if (enabledBanks[bankItem.id]) {
              const bankIsActive = defaultBank === bankItem.id;
              return (
                <ButtonChip
                  key={bankItem.id}
                  item={bankItem}
                  isActive={bankIsActive}
                  onClick={selectFavBankHandler.bind(null, bankItem.id)}
                />
              );
            }
            return null;
          })}
        </Stack>
      );
    }
  }, [
    activeStep,
    banksList,
    defaultBank,
    enabledBanks,
    selectBankHandler,
    selectFavBankHandler,
  ]);

  const step2Completed = useMemo(() => {
    let bankIsSelected = false;
    banksList.forEach((bankItem) => {
      if (enabledBanks[bankItem.id]) {
        bankIsSelected = true;
      }
    });
    return bankIsSelected;
  }, [banksList, enabledBanks]);

  const step3Completed = useMemo(() => {
    let bankIsSelected = false;
    banksList.forEach((bankItem) => {
      if (defaultBank === bankItem.id) {
        bankIsSelected = true;
      }
    });
    return bankIsSelected;
  }, [banksList, defaultBank]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
        <Paper
          square
          elevation={6}
          sx={{
            display: "flex",
            alignItems: "center",
            height: 50,
            pl: 2,
            bgcolor: "background.default",
          }}
        >
          <Typography>{steps[activeStep].label}</Typography>
        </Paper>
        <Box sx={{ height: 255, maxWidth: 400, width: "100%", p: 2 }}>
          {stepperDescription}
        </Box>
        <MobileStepper
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={activeStep === 2 ? saveBankSettingsHandler : handleNext}
              disabled={
                (activeStep === 1 && !step2Completed) ||
                (activeStep === 2 && !step3Completed)
              }
            >
              {activeStep === maxSteps - 1 ? "Finish" : "Continue"}
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </Box>
    </Box>
  );
};

export default NoBankAlert;
