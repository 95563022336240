import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Auth,
  EmailAuthProvider,
  User,
  UserCredential,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  updateProfile,
} from "firebase/auth";

import { firebaseAuth } from "../config/firebase";

export interface AuthProviderProps {
  children?: ReactNode;
}

export interface UserContextState {
  isAuthenticated: boolean;
  isLoading: boolean;
  id?: string;
}

export interface AuthContextModel {
  auth: Auth;
  user: User | null;
  userId: string;
  isLoading: boolean;
  successMsg: string | null;
  signIn: (email: string, password: string) => Promise<UserCredential>;
  signUp: (email: string, password: string) => Promise<UserCredential>;
  logout: () => Promise<void>;
  onChangePassword: (curPassword: string, newPassword: string) => void;
  onChangeProfile: (userName: string) => void;
  onCloseAlert: () => void;
  // sendPasswordResetEmail?: (email: string) => Promise<void>;
}

export const UserStateContext = createContext<UserContextState>(
  {} as UserContextState
);

export const AuthContext = createContext<AuthContextModel>(
  {} as AuthContextModel
);

const AuthContextProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [user, setUser] = useState<User | null>(null);
  const [userId, setUserId] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [successMsg, setSuccessMsg] = useState<string | null>(null);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (successMsg) {
      timer = setTimeout(() => {
        setSuccessMsg(null);
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [successMsg]);

  const signUp = useCallback(
    (email: string, password: string): Promise<UserCredential> =>
      createUserWithEmailAndPassword(firebaseAuth, email, password),
    []
  );

  const signIn = useCallback(
    (email: string, password: string): Promise<UserCredential> =>
      signInWithEmailAndPassword(firebaseAuth, email, password),
    []
  );

  // function resetPassword(email: string): Promise<void> {
  //   return sendPasswordResetEmail(firebaseAuth, email);
  // }

  const updateUserProfile = useCallback(
    (userName: string) => {
      if (user) {
        updateProfile(user, {
          displayName: userName,
        })
          .then(() => {
            // setUser((prevState) => prevState);
            setSuccessMsg(`Profile Updated Successfully!`);
          })
          .catch((error) => {
            // console.log("profile update error", error);
          });
      }
    },
    [user]
  );

  const updateUserPassword = useCallback(
    (curPassword: string, newPassword: string) => {
      if (user) {
        const email = user?.email ? user.email : null;
        if (email) {
          const credential = EmailAuthProvider.credential(email, curPassword);
          reauthenticateWithCredential(user, credential)
            .then((currentUser) => {
              updatePassword(currentUser.user, newPassword)
                .then(() => {
                  setSuccessMsg(`Password Updated Successfully!`);
                })
                .catch((error) => {
                  // console.log("password change error", error);
                });
              setUser(currentUser.user);
            })
            .catch((error) => {
              // console.log("reauthenticate error", error);
            });
        }
      }
    },
    [user]
  );

  const logout = useCallback((): Promise<void> => signOut(firebaseAuth), []);

  const handleAlertClose = useCallback(() => setSuccessMsg(null), []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (currentUser) => {
      setUser(currentUser);
      setUserId(currentUser?.uid ? currentUser.uid : "");
      setIsLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const values: AuthContextModel = useMemo(
    () => ({
      auth: firebaseAuth,
      user,
      userId,
      isLoading,
      successMsg,
      signUp,
      signIn,
      // resetPassword,
      logout,
      onChangePassword: updateUserPassword,
      onChangeProfile: updateUserProfile,
      onCloseAlert: handleAlertClose,
    }),
    [
      user,
      userId,
      isLoading,
      successMsg,
      signUp,
      signIn,
      logout,
      updateUserPassword,
      updateUserProfile,
      handleAlertClose,
    ]
  );
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;

export function useAuth(): AuthContextModel {
  return useContext(AuthContext);
}

export const useUserContext = (): UserContextState => {
  return useContext(UserStateContext);
};
