import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Moment } from "moment";
import { getDate, getMonthName, getWeekDay } from "../../helpers/date";

const CalenderCard = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "1px solid white",
  borderBottom: `4px solid white`,
  borderRadius: 12,
  overflow: "hidden",
}));

const CalenderMonth = styled(Box)(() => ({
  backgroundImage: "linear-gradient(to right, #3a9bc2, #0d4f6d)",
  padding: "0 1.5rem",
  fontSize: "0.8rem",
}));

const CalenderDate = styled(Box)(() => ({
  paddingTop: "0.1rem",
  fontSize: "1rem",
  fontWeight: "bold",
}));

const CalenderDay = styled(Box)(() => ({
  fontSize: "0.7rem",
  paddingBottom: "0.1rem",
}));

interface Props {
  date: Moment;
}

const DateCard: React.FC<Props> = ({ date }) => {
  const month = getMonthName(date);
  const calDate = getDate(date);
  const weekDay = getWeekDay(date);

  return (
    <CalenderCard component="div">
      <CalenderMonth>{month}</CalenderMonth>
      <CalenderDate>{calDate}</CalenderDate>
      <CalenderDay>{weekDay}</CalenderDay>
    </CalenderCard>
  );
};

export default DateCard;
