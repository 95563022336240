import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Option } from "../../../models";

interface Props {
  fieldType: string;
  fieldName: string;
  fieldLabel: string;
  fieldValue: string;
  fieldOptions: Option[] | undefined;
  error: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  // ref: React.Ref<HTMLInputElement>;
}

const BankSelect: React.FC<Props> = ({
  fieldType,
  fieldName,
  fieldLabel,
  fieldValue,
  fieldOptions,
  error,
  onBlur,
  onChange,
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <FormControl
      sx={{ mb: 2, width: "100%" }}
      color="secondary"
      error={!!error}
    >
      <InputLabel id="select-helper-label">{fieldLabel}</InputLabel>
      <Select
        labelId="select-helper-label"
        id="select-helper"
        value={fieldValue}
        label={fieldLabel}
        onChange={handleChange}
      >
        {/* <MenuItem value="">
          <em>None</em>
        </MenuItem> */}
        {fieldOptions &&
          fieldOptions.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
      </Select>
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

export default BankSelect;
